import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = `${title || 'Home'} - AirShip Couriers`;
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = `${defaultTitle.current || 'Home'} - AirShip Couriers`;
        }
    }, [])
}

export default useDocumentTitle