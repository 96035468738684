import moment from "moment";
import React, {Fragment, useState} from 'react';
import NumberFormat from "react-number-format";

export function Input (props) {
    return (
        <>
            <input
                id={props.id}
                name={props.name}
                type={props.type || "text"}
                value={props.value}
                autoComplete={props.autoComplete}
                required={props.required}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={props.onChange}
            />
        </>
    )
}

export class DateText extends React.Component {
    render() {
        let value = this.props.value || this.props.date;
        let format = this.props.format || "MMM D, YYYY";
        if ( this.props.showTime ) {
            format = "MMM D, YYYY h:mm:ss A";
        }
        let dateValue = moment(value).format(format);
        if ( !value ) {
            dateValue = 'N/A';
        }

        return (
            <span>{dateValue}</span>
        )
    }
}

export class RelativeDate extends React.Component {
    render() {
        let dateValue = this.props.value || this.props.date;
        let timeElapsed = moment(new Date).diff(moment(dateValue), 'seconds');// + 5*60*60;
        let suffix = '';
        let relativeDate = moment(dateValue).format("MMMM D, YYYY LT");
        if ( this.props.dateOnly ) {
            relativeDate = moment(dateValue).format("LL");
        }

        if ( timeElapsed < 0 ) {
            relativeDate = '-';
        } else if ( timeElapsed < 60 ) {
            relativeDate = 'Moments ago';
        } else if ( timeElapsed < 120 ) {
            relativeDate = 'A minute ago';
        } else if ( timeElapsed < 3600 ) {
            relativeDate = Math.round(timeElapsed/60);
            suffix = 'mins ago';
        } else if ( timeElapsed < 3660 ) {
            relativeDate = 'an hour ago';
        } else if ( timeElapsed < 86400 ) {
            relativeDate = (timeElapsed/3600).toFixed(0);
            suffix = 'hrs ago';
        } else if ( timeElapsed < 172800 ) {
            relativeDate = '';
            suffix = 'Yesterday';
        } else if ( timeElapsed < 604800 ) {
            relativeDate = Math.round(timeElapsed/86400);
            suffix = 'days ago';
        }

        if ( this.props.titleCase ) {
            suffix = ( suffix || '' ).toTitleCase();
        } else {
            relativeDate = ( relativeDate || '').toString().toLowerCase();
        }

        const dateText = !this.props.dateOnly ?  ( dateValue ? <>
                <span
                    title={moment(dateValue).format("MMMM D, YYYY HH:mm:ss TZ")}>
                    {relativeDate}{relativeDate && <>&nbsp;</>}{suffix}
                </span>
        </> : null ) : moment(dateValue).format('LL');

        return (
            <>
                {dateText}
            </>
        )
    }
}

export class CurrencyText extends React.Component {
    render() {
        let currency = this.props.symbol || '$', value = this.props.value || 0;
        if ( value < 0 ) {
            currency = `-${currency}`;
            value = value * -1;
        }
        let negativeClass = '';

        if ( this.props.value < 0 ) {
            negativeClass = 'negative';
        }

        let suffix = '', decimalScale = 2, fixedDecimalScale = true;

        if ( this.props.shorten ) {
            if ( Math.abs(value) >= 1000000000 ) {
                value /= 1000000000;
                suffix = 'b';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 1000000 ) {
                value /= 1000000;
                suffix = 'm';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 10000 ) {
                value /= 1000;
                suffix = 'k';
                decimalScale = 1;
                fixedDecimalScale = false;
            }

            // value = value.toFixed(1);
        }

        fixedDecimalScale = fixedDecimalScale && Math.round(value) !== value || this.props.fixedDecimalScale;

        return (
            <>
                {value > 0 &&
                    <span className={negativeClass + " text-right"}>
                    <NumberFormat
                        value={value}
                        decimalScale={decimalScale}
                        fixedDecimalScale={fixedDecimalScale}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={currency}
                        suffix={suffix}
                        autoComplete={"off"}
                        title={this.props.value}
                    />
                </span>
                }
                {!value &&
                    <>
                        {this.props.default || '$0'}
                    </>}
            </>
        )
    }
}

export class NumberText extends React.Component {
    render() {
        let negativeClass = '';
        let value = this.props.value || 0;

        if ( value < 0 ) {
            negativeClass = 'negative';
        }

        let suffix = '', decimalScale = 2, fixedDecimalScale = true;

        if ( this.props.shorten ) {
            if ( Math.abs(value) >= 1000000000 ) {
                value /= 1000000000;
                suffix = 'b';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 1000000 ) {
                value /= 1000000;
                suffix = 'm';
                decimalScale = 1;
                fixedDecimalScale = false;
            } else if ( Math.abs(value) >= 10000 ) {
                value /= 1000;
                suffix = 'k';
                decimalScale = 1;
                fixedDecimalScale = false;
            }

            // value = value.toFixed(1);
        }

        fixedDecimalScale = fixedDecimalScale && Math.round(value) !== value || this.props.fixedDecimalScale;

        return (
            <>
                {value > 0 &&
                    <span className={negativeClass + " text-right"}>
                    <NumberFormat
                        value={value}
                        decimalScale={decimalScale}
                        fixedDecimalScale={fixedDecimalScale}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={''}
                        suffix={suffix}
                        autoComplete={"off"}
                        title={this.props.value}
                    />
                </span>
                }
                {!value &&
                    <>
                        {this.props.default || '0'}
                    </>}
            </>
        )
    }
}

export function ShortenInText(props) {

    let {text, maxLength} = props;

    let shortenedText = `${text}`;

    if ( !maxLength ) maxLength = 20;

    let length = text?.length || 0;

    if ( length > maxLength ) {
        let firstStop = Math.max(9, ( maxLength / 2 ) - 2 );
        let secondStop = Math.min(length - 1, length - firstStop + 2 );
        let firstString = text.split('').splice(0, firstStop).join('');
        let secondString = text.split('').splice(secondStop, length - firstStop).join('');
        shortenedText = `${firstString}...${secondString}`;
    }

    const [shortened, setShortened] = useState(true);

    return (
        <>
            {shortened && <span className={'clickable'} onClick={() => setShortened(false)} title={text}>{shortenedText}</span>}
            {!shortened && <span className={'clickable'} onClick={() => setShortened(true)}>{text}</span>}
        </>
    )
}
