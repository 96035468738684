export default props => {
    return (
        <>
            <div className="text-center submenu">

                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2">

                    <div>
                        <a href={"/settings/account"} className={props.page === 'account' ? 'submenu-item submenu-active' : 'submenu-item'}>Update Account</a>
                    </div>
                    <div>
                        <a href={"/settings/addresses"} className={props.page === 'addresses' ? 'submenu-item submenu-active' : 'submenu-item'}>Shipping Addresses</a>
                    </div>
                    <div>
                        <a href={"/settings/notifications"} className={props.page === 'notifications' ? 'submenu-item submenu-active' : 'submenu-item'}>Notifications</a>
                    </div>
                    <div>
                        <a href={"/settings/password"} className={props.page === 'password' ? 'submenu-item submenu-active' : 'submenu-item'}>Change Password</a>
                    </div>

                </div>

            </div>
            <br />
            <br />
        </>
    )
}