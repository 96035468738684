import {InformationCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";

export default props => {
    return (
        <>
            <div className="submenu text-center">

                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2">

                    <div>
                        <a href={"/packages/active"} className={props.page === 'active' || props.page === '' || !props.page ? 'submenu-item submenu-active' : 'submenu-item'}>Active</a>
                    </div>
                    <div>
                        <a href={"/packages/new"} className={props.page === 'new' ? 'submenu-item submenu-active' : 'submenu-item'}>New</a>
                    </div>
                    <div>
                        <a href={"/packages/delivered"} className={props.page === 'delivered' ? 'submenu-item submenu-active' : 'submenu-item'}>Delivered</a>
                    </div>
                    <div>
                        <a href={"/packages/inactive"} className={props.page === 'inactive' ? 'submenu-item submenu-active' : 'submenu-item'}>Inactive</a>
                    </div>

                </div>

            </div>
            <br />
            <br />
        </>
    )
}