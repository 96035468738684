import {Fragment, useEffect, useState} from "react";
import Header from "../../../Components/Header";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import {CurrencyText, Input} from "../../../Utils/Template";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";


export default function Rates(props) {

  useDocumentTitle('Rates');

  useEffect(() => {
  }, []);

  const [packageWeight, setPackageWeight] = useState(1);
  const [estimatedFreight, setEstimatedFreight] = useState(5);

  const estimateFreight = e => {
    if ( e.target.value === '' ) {
      setPackageWeight('');
      setEstimatedFreight(0);
    } else {
      let weight = Math.max(1, Math.ceil(+e.target.value));
      setPackageWeight(weight);
      let charges = 5 + (+weight - 1) * 3;
      setEstimatedFreight(charges);
    }
  };

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Rates"}></Header>
            <br />
            <Container>
              <div className="">

                <InfoMenu page={props.page || 'rates'} />

                <h2 className={'page-sub-title'}>
                  <Translate text={"We're not the cheapest."} />
                </h2>
                <br />
                <p><Translate text={"We're certainly not expensive either. Far from it. However, at any rate, you get what you pay for here: fast shipping, stellar support and you're treated like family."} /></p>
                <br />
                <p><Translate text={"Members also get cool benefits and a reward on their spend, with redeemable points for each pound of package shipped and paid for. Those points can be used to pay for other packages."} /></p>

                <br /><hr /><br />

                <h2 className={'page-sub-title'}><Translate text={"Freight Charges"} /></h2>
                <br />
                <p><Translate text={"Our pricing is simple. It's US$5 for the first pound and US$3 for each additional pound. Rates for heavy cargo are negotiable."} /></p>
                <br />

                <p className={'text-blue-600'}><Translate text={"Estimate your freight charges here:"} /></p>

                <br />

                <div>
                  <label htmlFor="packageWeight"
                         className="block text-sm font-medium text-gray-700 form-field-label">
                    <Translate text={"What's the weight in pounds?"}/>
                  </label>
                  <div className="mt-1">
                    <Input
                        id="packageWeight"
                        name="packageWeight"
                        type="number"
                        className={"form-field-input"}
                        value={packageWeight}
                        required={true}
                        onChange={e => estimateFreight(e)}
                    />
                  </div>
                  <br />
                  <label htmlFor="packageWeight"
                         className="block text-xl font-medium text-blue-800 form-field-label">
                    <Translate text={"Estimated charges"} />: <span><CurrencyText value={estimatedFreight} /></span>
                  </label>
                </div>

              </div>
              <InfoFooter />

            </Container>
          </div>
        </div>

      </>
  );
}