import {InformationCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import Translate from "../../../../../Utils/Translate";

export default props => {
    return (
        <>
            <div className="submenu text-center">

                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2">

                    <div>
                        <a href={"/payments/statement"} className={props.page === 'statement' || props.page === '' || !props.page ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"Statement"} /></a>
                    </div>
                    <div>
                        <a href={"/payments/transactions"} className={props.page === 'transactions' ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"Transactions"} /></a>
                    </div>
                    <div>
                        <a href={"/payments/rewards"} className={props.page === 'rewards' ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"Rewards"} /></a>
                    </div>
                    <div>
                        <a href={"/info/how-to-pay"} className={'submenu-item'}><Translate text={"How to Pay"} /></a>
                    </div>

                </div>

            </div>
            <br />
            <br />
        </>
    )
}