import {Fragment, useEffect, useState} from "react";
import Http from "../../Utils/Http";
import Notifier from "../../Utils/Notifier";

import Template, { Input } from "../../Utils/Template";
import Translate from "../../Utils/Translate";
import useDocumentTitle from "../../Utils/DocumentTitle";

export default function Logout() {

  useDocumentTitle('Logout');

  useEffect(() => {
    submitLogout();
  });

  const submitLogout = async () => {
    try {
      Http.request({uri: '/auth/logout'});
    } catch (e) {}

    setTimeout(() => {
      localStorage.removeItem('access_token');
      localStorage.clear();
      window.setConfig();
      window.location.replace(`/`);
    }, 1000);
  }

    return (
      <Fragment>
        <Translate text={"Please wait while we redirect you."} />...
      </Fragment>
    );
  }