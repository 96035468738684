import Translate from "../Utils/Translate";

export default function Footer() {
    return (
      <div style={{textAlign:'center'}}>
          <hr />
          <br />
          <small className={"text-blue-900"}>{process.env.REACT_APP_APP_NAME}<br /><small>Build #{process.env.REACT_APP_BUILD_NUMBER} - Released {process.env.REACT_APP_RELEASE_DATE}</small></small>
          <br /><br />
      </div>
    );
  }