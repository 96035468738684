import React, { useEffect } from 'react';

const ContactCentreChatWidget = ({ channelId, channelName, environment, scriptHost, version, userData = {}, authType = 'guest' }) => {
  useEffect(() => {
    // Load the script dynamically
    const script = document.createElement('script');
    scriptHost = scriptHost || process.env.REACT_APP_CONTACT_CENTRE_SCRIPT_HOST || 'https://s3.us-east-1.amazonaws.com/swf.cdn/contact-centre';
    let env = { 'development': '-dev', 'dev': '-dev', 'qa': '-qa', 'uat': '-uat', 'production': '' }[environment] || '';
    version = version || process.env.REACT_APP_CONTACT_CENTRE_VERSION || 'latest';
    const now = new Date();
    const timestamp = `${now.getUTCFullYear()}${String(now.getUTCMonth() + 1).padStart(2, '0')}${String(now.getUTCDate()).padStart(2, '0')}${String(now.getUTCHours()).padStart(2, '0')}`;
    script.src = `${scriptHost}/chat-widget${env}-${version}.js?t=${timestamp}`;
    script.async = true;
    script.onload = () => {
      // Once the script is loaded, initialize the chat widget
      if (window.ContactCentre) {
        window.ContactCentre.launchChat({
          channelId,
          channelName,
          environment,
          authType,
          userData
        });
      }
    };
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, [channelId, channelName, environment]);

  return null; // This component doesn't render anything directly
};

export default ContactCentreChatWidget;
