import { render } from "react-dom";
import App from "./App";
import { createRoot } from 'react-dom/client';

window.redirectByDomain = () => window.location.assign(`https://portal.airshipcouriers.com${window.location.pathname}`);

if ( ['airshipcouriers.com', 'www.airshipcouriers.com', 'airboxcouriers.com', 'portal.airboxcouriers.com', 'www.airboxcouriers.com'].includes(window.location.host) ) {
  window.redirectByDomain();
}

if ( window.location.host.indexOf('airshipcouriers.com') > -1 && window.location.protocol.indexOf('https') < 0 ) {
  window.redirectByDomain();
}

window.setConfig = async () => {
  let configHost = process.env.REACT_APP_API_CONFIG_HOST;
  const response = await fetch(configHost).then(response => response.json());
  localStorage.setItem('HOST_CONFIG', JSON.stringify(response));
};

window.getConfig = key => {
  let configData = {};
  let configString = localStorage.getItem('HOST_CONFIG');
  if ( configString ) configData = JSON.parse(configString);
  configData = {...configData, ...process.env};
  return configData[key] || configData[`AIRSHIP_API_${key}`] || configData[`AIRSHIP_API_MVP_${key}`] || configData[`REACT_APP_API_${key}`] || configData[key.replace('REACT_APP_API_', '')] || null;
};

window.getServiceEndpoint = service => {
  let envQualifier = 'NON_PROD';
  if ( window.location.host.indexOf('local') > 0 ) envQualifier = 'LOCAL';
  if ( window.location.host.indexOf('.airshipcouriers.com') > 0 ) envQualifier = 'PROD';
  if ( window.location.host.indexOf('portal.airshipcouriers.com') === 0 ) envQualifier = 'PROD';
  // if ( window.location.host.indexOf('airshipcouriers.com') > -1 ) envQualifier = 'PROD';
  if ( window.location.host.indexOf('box-uat.c5office.com') === 0 ) envQualifier = 'UAT';
  if ( window.location.host.indexOf('qa.airshipcouriers.com') === 0 ) envQualifier = 'QA';
  if ( window.location.host.indexOf('dev.airshipcouriers.com') === 0 ) envQualifier = 'dev';
  let finalConfigKey = `${(service || 'AIRSHIP_API_MVP').toUpperCase()}_HOST_${envQualifier}`;
  return window.getConfig(finalConfigKey);
};

window.getAxiosConfig = () => {
  let headers = {access_token: localStorage.getItem('access_token')};
  return {headers};
};

window.friendlyCase = function(string) {
  let result = string.replace( /([A-Z])/g, " $1" );
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  let knownWords = {
    "Trn": 'TRN',
    "Date Of Birth": 'Date of Birth',
  }
  for ( let w in knownWords ) {
    if ( finalResult === w ) {
      return knownWords[w];
    }
  }
  return finalResult;
};

String.prototype.toFriendlyCase = function() {
  return window.friendlyCase(this);
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <App />
);