import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Statement from "./pages/Statement";
import Rewards from "./pages/Rewards";
import Transactions from "./pages/Transactions";

export default function Payments() {
    return (
      <main>
        <Routes>
            <Route path="/statement" element={<Statement page={'statement'} />} />
            <Route path="/rewards" element={<Rewards page={'rewards'} />} />
            <Route path="/transactions/post-payment" element={<Transactions page={'transactions'} deepLink={'posting'} />} />
            <Route path="/transactions/card-payment" element={<Transactions page={'transactions'} deepLink={'card-payment'} />} />
            <Route path="/transactions/pay-with-lynk" element={<Transactions page={'transactions'} deepLink={'lynk-payment'} />} />
            <Route path="/transactions/thank-you" element={<Transactions page={'transactions'} deepLink={'card-payment-completed'} />} />
            <Route path="/transactions/paid-with-lynk" element={<Transactions page={'transactions'} deepLink={'lynk-payment-completed'} />} />
            <Route path="/transactions" element={<Transactions page={'transactions'} />} />
            <Route path="*" element={<Statement page={'statement'} />} />
        </Routes>
      </main>
    );
  }