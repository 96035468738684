import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import {Input} from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import SettingsMenu from "../components/SettingsMenu";
import Translate from "../../../../../Utils/Translate";
import moment from "moment";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UpdateAccount() {

  useDocumentTitle('Account Settings');

  const [accountInfo, setAccountInfo] = useState({meta: {}, gender: 'Select Gender'});

  const handleChange = e => setAccountInfo({...accountInfo, [e.target.name]: e.target.value});

  const setGender = gender => {
    setAccountInfo({...accountInfo, gender});
  }

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    let body = {};
    const response = await Http.request({uri: `/account/get`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.body.dateOfBirth ) {
      response.body.dateOfBirth = moment(new Date(response.body.dateOfBirth)).format('YYYY-MM-DD');
    }
    if ( !response.body.gender ) {
      response.body.gender = 'Select a gender';
    }
    setAccountInfo(response.body)
  }

  const saveAccountInfo = async () => {
    let body = accountInfo;
    const response = await Http.request({uri: `/account/patch/personal`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
  }

  const handleEnterKey = e => {
    if ( e.key === 'Enter' ) {
      saveAccountInfo();
    }
  }

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Update Account"}></Header>
            <br />
            <Container>
              <div className="" onKeyPress={e => handleEnterKey(e)}>

                <SettingsMenu page={'account'} />

                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"First name"} />:
                    </label>
                    <div className="mt-1">
                      <Input
                          id="firstName"
                          name="firstName"
                          type="text"
                          className={"form-field-input"}
                          value={accountInfo.firstName}
                          required={true}
                          autoComplete="firstName"
                          onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"Last name"} />:
                    </label>
                    <div className="mt-1">
                      <Input
                          id="lastName"
                          name="lastName"
                          type="text"
                          className={"form-field-input"}
                          value={accountInfo.lastName}
                          required={true}
                          autoComplete="lastName"
                          onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>

                </div>

                <br />

                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                  <div>
                    <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"Date of birth"} />:
                    </label>
                    <div className="mt-1">
                      <Input
                          id="dateOfBirth"
                          name="dateOfBirth"
                          type="date"
                          className={"form-field-input"}
                          value={accountInfo.dateOfBirth}
                          required={true}
                          autoComplete="dateOfBirth"
                          onChange={e => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"Gender"} />:
                    </label>
                    <div className="mt-1">
                      <Listbox value={accountInfo.gender} onChange={setGender}>
                        {({ open }) => (
                            <>
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                  <span className="block truncate">{accountInfo.gender}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {[{"id": "", "value": "Select Gender"}, {"id": "Female", "value": "Female"}, {"id": "Male", "value": "Male"}].map((gender) => (
                                        <Listbox.Option
                                            key={gender.value}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={gender.value}
                                        >
                                          {({ selected, active }) => (
                                              <>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {gender.value}
                                    </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                                ) : null}
                                              </>
                                          )}
                                        </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                        )}
                      </Listbox>

                    </div>
                  </div>

                </div>

                <br />

                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                  <div className={'col-span-2'}>
                    <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"Email address"} />:
                    </label>
                  </div>
                  <div className={'col-span-1'}>
                    <div className="mt-1">
                      {!accountInfo.meta?.disableEmail &&
                      <Input
                          id="emailAddress"
                          name="emailAddress"
                          type="text"
                          className={"form-field-input"}
                          value={accountInfo.emailAddress}
                          required={true}
                          autoComplete="emailAddress"
                          onChange={e => handleChange(e)}
                      />}
                      {accountInfo.meta?.disableEmail &&
                        <span title={"We understand it might be a little inconvenient, but this email address has been verified so you cannot change it now. We will create a feature to allow changing and re-verifying your details. It's for your protection and others', so people don't steal identities. We'll fix it soon!"}><InformationCircleIcon className={'inline clickable'} height={"18"} />&nbsp;{accountInfo.emailAddress}</span>
                      }
                    </div>
                  </div>

                  <div className={'col-span-2'}>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"Phone number"} />:
                    </label>
                  </div>
                  <div className={'col-span-1'}>
                    <div className="mt-1">
                      {!accountInfo.meta?.disablePhoneNumber &&
                          <>
                            <Input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                className={"form-field-input"}
                                value={accountInfo.phoneNumber}
                                required={false}
                                autoComplete="phoneNumber"
                                onChange={e => handleChange(e)}
                            />
                          </>
                      }
                      {accountInfo.meta?.disablePhoneNumber &&
                          <span title={"We understand it might be a little inconvenient, but this phone number has been verified so you cannot change it now. We will create a feature to allow changing and re-verifying your details. It's for your protection and others', so people don't steal identities. We'll fix it soon!"} ><InformationCircleIcon className={'inline clickable'} height={"18"}/>&nbsp;{accountInfo.phoneNumber}</span>
                      }
                    </div>
                  </div>

                </div>

                <br />

                <div>
            <div>
              <button
                  type="submit"
                  className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={() => saveAccountInfo()}
              >
                <Translate text={"Save"} />
              </button>
            </div>

                  {(accountInfo.meta?.disableEmail || accountInfo.meta?.disablePhoneNumber) &&
                      <>
                        <br/>
                        <hr/>
                        <br/>

                        <p className={'text-sm text-gray-500'}><InformationCircleIcon className={'inline clickable'} height={"18"} />&nbsp;We understand it might be a little inconvenient, but this {accountInfo.meta?.disableEmail && <>email address</>}&nbsp;{accountInfo.meta?.disablePhoneNumber && <>phone number</>} has been verified so you cannot change it now. We will create a feature to allow changing and re-verifying your details. It's for your protection and others', so people don't steal identities. We'll fix it soon!
                        </p>
                      </>
                  }

          </div>

              </div>
            </Container>
          </div>
        </div>
      </>
  );
}