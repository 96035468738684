import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Container";
import Translate from "../../../../Utils/Translate";
import useDocumentTitle from "../../../../Utils/DocumentTitle";

export default function StartRegistration() {
  useDocumentTitle('Register');

  const [newAccount, setNewAccount] = useState({});
  const [currentPage, setCurrentPage] = useState('start');
  const [sessionState, setSessionState] = useState(null);

  const handleChange = e => {
    setNewAccount({ ...newAccount, [e.target.name]: e.target.value });
  }

  const [confirmRequest, setConfirmRequest] = useState({ email: localStorage.getItem('REGISTER_PARAMS_EMAIL') });

  const handleChangeConfirmation = e => {
    setConfirmRequest({ ...confirmRequest, [e.target.name]: e.target.value });
  }

  const submitRegistration = async () => {
    let body = { ...newAccount };
    const response = await Http.request({ uri: '/auth/register', body });
    if (response.notification) {
      Notifier(response.notification);
    }
    console.log(response.body)
    if (response.result === 'success') {
      localStorage.setItem('REGISTER_PARAMS_EMAIL', newAccount.emailAddress);
      localStorage.setItem('state', response.body.state);
      let sendCodeResponse = await Http.request({ uri: '/auth/register/send-code', body: { state: response.body.state } });
      localStorage.setItem('stateChannel', sendCodeResponse.body.stateChannel);
      if (sendCodeResponse.notification) {
        Notifier(sendCodeResponse.notification);
      }
      if (sendCodeResponse.result === 'success') {
        window.setTimeout(() => window.location.replace(`/auth/register/confirm`), 1000);
      }
    }
  }

  const submitConfirmRequest = async () => {
    let body = { ...confirmRequest, state: localStorage.getItem('state') };
    const response = await Http.request({ uri: '/auth/register/confirm', body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      localStorage.clear();
      window.location.replace(`/auth/login`);
    }
  }

  const handleEnterKeyRegister = e => {
    if (e.key === 'Enter') {
      submitRegistration();
    }
  }

  const handleEnterKeyConfirmation = e => {
    if (e.key === 'Enter') {
      submitConfirmRequest();
    }
  }

  const handleEnterKeyPassword = e => {
    if (e.key === 'Enter') {
      setCurrentPage('password');
    }
  }

  return (
    <Fragment>
      <div className="flex min-h-full">
        <div className="w-full py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Register"}></Header>
          <br />
          <Container>
            <div className="">
              <div>
                <p className="mt-2 text-sm text-gray-600 text-center">
                  <Translate text={"Create a free account and start shipping."} /><br />
                  <Translate text={"Already a member?"} />&nbsp;
                  <Translate text={"Go to"} />&nbsp;
                  <a href={"/auth/login"}><Translate text={"login screen"} /></a>.
                </p>
              </div>
              <br />
              <hr />
              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-6">
                    {(!currentPage || currentPage === '' || currentPage === 'start') &&
                      <div onKeyPress={e => handleEnterKeyPassword(e)}>
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"Hi, what's your name?"} />
                        </label>
                        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4 grid--m-top">
                          <div>
                            <div className="">
                              <Input
                                id="firstName"
                                name="firstName"
                                type="text"
                                className={"form-field-input"}
                                value={newAccount.firstName}
                                required={true}
                                autoComplete="firstName"
                                onChange={e => handleChange(e)}
                              />
                            </div>
                            <p className="font-small text-gray-600 text-sm">
                              <small><Translate text={"First name"} /></small>
                            </p>
                          </div>
                          <div>
                            <div className="">
                              <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                className={"form-field-input"}
                                value={newAccount.lastName}
                                required={true}
                                autoComplete="lastName"
                                onChange={e => handleChange(e)}
                              />
                            </div>
                            <p className="font-small text-gray-600 text-sm">
                              <small><Translate text={"Last name"} /></small>
                            </p>
                          </div>
                        </div>

                        <br />

                        <label htmlFor="userName" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"What's your phone # (or email)?"} />
                        </label>
                        <div className="grid grid-cols-1 grid--m-top">
                          <div>
                            <div className="mt-1">
                              <Input
                                id="userName"
                                name="userName"
                                type="text"
                                className={"form-field-input"}
                                value={newAccount.userName}
                                onChange={e => handleChange(e)}
                              />
                            </div>
                          </div>
                        </div>

                        <br />

                        <div>
                          <button
                            type="submit"
                            className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setCurrentPage('password')}
                          >
                            <Translate text={"Continue"} />
                          </button>
                        </div>

                      </div>
                    }

                    {currentPage === 'password' &&
                      <div onKeyPress={e => handleEnterKeyRegister(e)}>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"Please set a strong password"} />
                        </label>

                        <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4 grid--m-top">
                          <div>
                            <div className="">
                              <Input
                                id="password"
                                name="password"
                                type="password"
                                className={"form-field-input"}
                                value={newAccount.password}
                                onChange={e => handleChange(e)}
                              />
                            </div>
                            <p className="font-small text-gray-600 text-sm">
                              <small><Translate text={"Password"} /></small>
                            </p>
                          </div>
                          <div>
                            <div className="">
                              <Input
                                id="passwordConfirm"
                                name="passwordConfirm"
                                value={newAccount.passwordConfirm}
                                type="password"
                                onChange={e => handleChange(e)}
                              />
                            </div>
                            <p className="font-small text-gray-600 text-sm">
                              <small><Translate text={"Confirm password"} /></small>
                            </p>
                          </div>
                        </div>

                        <br />

                        <div>
                          <button
                            type="submit"
                            className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => submitRegistration()}
                          >
                            <Translate text={"Register"} />
                          </button>
                          &nbsp;
                          <button
                            type="submit"
                            className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setCurrentPage('start')}
                          >
                            <Translate text={"Go Back"} />
                          </button>
                        </div>
                      </div>
                    }

                    {currentPage === 'confirm' &&
                      <div onKeyPress={e => handleEnterKeyConfirmation(e)}>

                        <div>
                          <p className="mt-2 text-sm text-gray-600">
                            <Translate text={"Thanks. We're in your email inbox (hopefully not junk). Please check the email and retrieve the confirmation code."} />
                          </p>
                        </div>

                        <div className="space-y-1">
                          <label htmlFor="password" className="block text-sm font-medium text-gray-700 form-field-label">
                            <Translate text={"What's the confirmation code?"} />
                          </label>
                          <div className="mt-1">
                            <Input
                              id="confirmationCode"
                              name="confirmationCode"
                              type="text"
                              className={"form-field-input"}
                              value={newAccount.confirmationCode}
                              onChange={e => handleChangeConfirmation(e)}
                            />
                          </div>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => submitConfirmRequest()}
                          >
                            <Translate text={"Confirm"} />
                          </button>

                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Fragment>
  );

}