import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import ShippingAddress from "./pages/ShippingAddress";
import ChangePassword from "./pages/ChangePassword";
import UpdateAccount from "./pages/UpdateAccount";
import Notifications from "./pages/Notifications";
import Verification from "./pages/Verification";

export default function () {
    return (
      <main>
        <Routes>
          <Route path="/addresses" element={<ShippingAddress />} />
            <Route path="/account" element={<UpdateAccount />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/notifications" element={<Notifications />} />
          <Route path="/password" element={<ChangePassword />} />
            <Route path="*" element={<UpdateAccount />} />
        </Routes>
      </main>
    );
  }