import React, {Fragment, useEffect, useState} from "react";
import Header from "../../../Components/Header";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";
import {CreditCardIcon, PlusCircleIcon} from "@heroicons/react/20/solid";


export default function HowToPay(props) {

  useDocumentTitle('How to Pay')

  useEffect(() => {
  }, []);

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"How to Pay"}></Header>
            <br />
            <div className={'text-center'}>
              <span className={'clickable rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/card-payment')}><CreditCardIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Pay with Card"} /></span>&nbsp;&middot;&nbsp;<span className={'clickable rounded-md border border-transparent bg-green-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/post-payment')}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Post a Payment"} /></span>
            </div>
            <br />
            <Container>
              <div className="">

                <InfoMenu page={props.page || 'payments'} />

                <h2 className={'page-sub-title'}><Translate text={"Pay with your card"} /></h2>
                <br />
                <p><Translate text={"We accept payments with Visa, MasterCard, Amex and other international credit and debit cards."} /></p>
                <br />
                <p>
                  <a href={"/payments/transactions/card-payment"} className={'rounded-md border border-transparent bg-indigo-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 link-white-text'}><Translate text={"Pay with a Card"} /></a>
                </p>
                <br />
                <h2 className={'page-sub-title'}><Translate text={"Pay with Lynk"} /></h2>
                <br />
                <p><Translate text={"Pay your charges and duties for packages with Lynk beforehand, to speed up handling and delivery."} /></p>
                <br />
                <p><Translate text={"Scan the QR code below to pay with Lynk."} /></p>
                <br />
                <p>
                  <img alt={"Lynk QR Code for @AirShipCouriers"} src={'/assets/images/airshipcouriers-lynk-qr-card.png'} />
                </p>

                <br /><hr /><br />

                <h2 className={'page-sub-title'}><Translate text={"Got only cash?"} /></h2>
                <br />
                <p><Translate text={"We don't recommend it, but if you have to use dirty paper to pay, it will be accepted."} /></p>
              </div>
              <InfoFooter />
            </Container>
          </div>
        </div>

      </>
  );
}