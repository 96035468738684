import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Input, RelativeDate, ShortenInText} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  CurrencyDollarIcon, EyeIcon,
  InformationCircleIcon, PencilIcon, PlusCircleIcon,
  ScaleIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import SettingsMenu from "../components/PackagesMenu";
import PackagesMenu from "../components/PackagesMenu";
import axios from "axios";
import {Helmet} from "react-helmet";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

export default function Browse(props) {

  useDocumentTitle('Packages');

  const [packages, setPackages] = useState([]);
  const [invoiceMode, setInvoiceMode] = useState(false);
  const [trackingEditMode, setTrackingEditMode] = useState(false);
  const [itemEditTarget, setItemEditTarget] = useState(null);

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    let body = {};
    const response = await Http.request({uri: `/packages/list/${props.mode || 'active'}`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setPackages(response.body.packages);
  }

  const statusDefinitions = window.getConfig('STATUS_DEFINITIONS') || {};
  const getStatusStyle = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.style || `${status}`;
  }

  const getStatusName = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.label || `${status}`;
  }

  const startInvoiceUpload = packageId => {
    setInvoiceMode(true);
    setItemEditTarget(packageId);
  };

  const cancelInvoiceUpload = () => {
    setInvoiceMode(false);
    setItemEditTarget(null);
  };

  const selectFile = async e => {
    if ( e ) e.preventDefault();
    let fileList = e.target.files;
    let files = [];
    let fileCount = 1;
    for ( let key in fileList) {
      let file = fileList[key];
      if ( file.name && file.size && fileCount === 1 ) {
        fileCount++;
        files.push(file);
      }
    }

    const formData = new FormData();
    formData.append('invoiceBinary', files[0]);
    formData.append('payload', JSON.stringify({state: itemEditTarget}));

    await axios.post(window.getServiceEndpoint() + `/packages/${itemEditTarget}/add-invoice`, formData, window.getAxiosConfig())
        .then(result => {
          console.log(result.data);
          if (typeof result.data !== 'undefined') {
          }
          if ( result.data.notification ) {
            Notifier(result.data.notification);
          }
          setInvoiceMode(false);
          fetchPackages();
        }).catch(e => {
          if ( e.data.notification ) {
            Notifier(e.data.notification);
          }
        });
  };

  const startTrackingEditMode = (packageId, trackingNumber) => {
    if ( trackingNumber.indexOf('NO-TRACKING') === 0 ) trackingNumber = '';
    setPackageTrackingNumber(trackingNumber);
    setItemEditTarget(packageId);
    setTrackingEditMode(true);
  };

  const cancelTrackingEditMode = () => {
    setTrackingEditMode(false);
    setItemEditTarget(null);
  };

  const [packageTrackingNumber, setPackageTrackingNumber] = useState(null);

  const savePackageTrackingNumber = async () => {
    let body = {trackingNumber: packageTrackingNumber};
    const response = await Http.request({uri: `/packages/${itemEditTarget}/update-tracking-number`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      cancelTrackingEditMode();
      fetchPackages();
    }
  };

  const packageHelpDescriptions = {
    active: "Packages that have not delivered to you yet.",
    new: "Packages that we haven't seen yet.",
    delivered: "Packages that you have already collected.",
    inactive: "Packages no longer being tracked."
  };

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Packages"}></Header>
            <br />
            <div className={'text-center'}>
              <span className={''}>
                  <a href={"/packages/pre-register"} className={'rounded-md border border-transparent bg-green-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 force-text-white'}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Pre-register a Package"} /></a>
              </span>
            </div>
            <br />
            <Container>
              <div className="">

                <PackagesMenu page={props.mode || 'active'} />

                <div className={'text-center'}>
                  {packages.length} <Translate text={props.mode || 'active'} />
                  <br />
                  <small className={'text-gray-600'}>{packageHelpDescriptions[props.mode]}</small>
                </div>

                <br />

                <div className="">
                  {packages.map((packageItem, key) => (
                      <Fragment key={key}>
                        <div className={"package-info shadow-md"}>
                          <h2 className={'package-info-title'}>
                            {packageItem.title}
                            <span className={'pull-right small-invoice-link text-green-500 clickable'} onClick={() => startInvoiceUpload(packageItem._id)}>
                              {packageItem.invoiceStatus !== 'received' && !invoiceMode &&
                                  <>
                                    <ArrowUpTrayIcon height={"18"} className={"inline"} />&nbsp;<Translate text={"Invoice"} />
                                  </>
                              }
                            </span>
                            </h2>
                            <hr />
                            {invoiceMode && itemEditTarget === packageItem._id &&
                                <><div className={'bg-white shadow p-10 rounded-all'}>
                                  <p className={'text-sm'}><><Translate text={"Please select a PDF, JPG/JPEG or PNG file to serve as invoice for this package."} /> <span className={'clickable text-red-700'} onClick={() => cancelInvoiceUpload()}><Translate text={"Cancel"} /></span> </></p>
                                  <br />
                                  <p>
                                    <label htmlFor={"invoiceFileChooser"} className={'file-chooser-label'}><ArrowUpTrayIcon className={'inline'} height={"16"}/> <Translate text={"Select a file"} /></label>
                                    <input type="file" id={"invoiceFileChooser"} hidden onChange={selectFile} multiple={false}/>
                                  </p>
                                </div><br /></>
                            }
                            {trackingEditMode && itemEditTarget === packageItem._id &&
                                <><div className={'bg-white shadow p-10 rounded-all'}>
                                  <p className={'text-sm'}><><Translate text={"Paste tracking number below."} /> <span className={'clickable text-red-700'} onClick={() => cancelTrackingEditMode()}><Translate text={"Cancel"} /></span> </></p>
                                  <br />
                                  <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                                    <div>
                                      <div className="mt-1">
                                        <Input
                                            id="packageTrackingNumber"
                                            name="packageTrackingNumber"
                                            type="text"
                                            className={"form-field-input"}
                                            value={packageTrackingNumber}
                                            required={true}
                                            onChange={e => setPackageTrackingNumber(e.target.value)}
                                        />
                                      </div>
                                    </div>

                                  </div>

                                  <br/>

                                  <div>
                                    <div>
                                      <button
                                          type="submit"
                                          className="rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                          onClick={() => savePackageTrackingNumber()}
                                      >
                                        <Translate text={"Set"}/>
                                      </button>
                                    </div>
                                  </div>

                                </div><br /></>
                            }
                            <div className={'package-info-tracking'}>
                              <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-2">
                                <div>
                                  <strong><Translate text={'Tracking #'} /></strong>: <ShortenInText text={packageItem.trackingNumber} maxLength={12} /> {packageItem.movementStatus === 'new' && <><a className={'clickable'} onClick={() => {
                                  startTrackingEditMode(packageItem._id, packageItem.trackingNumber);
                                }}><PencilIcon height={"16"} className={"inline"} /></a></>}{packageItem.trackingNumber.indexOf('NO-TRACKING') < 0 && <>&nbsp;<a href={`https://parcelsapp.com/en/tracking/${packageItem.trackingNumber}`} target={"_blank"} title={"Track this Package"}><EyeIcon height={"16"} className={"inline"} /></a></>}
                                </div>
                                <div>
                                  <strong><Translate text={'Package #'} /></strong>: {packageItem.packageNumber}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className={'package-info-annotation'}>
                              <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 gap-2">
                                <div>
                                  <ScaleIcon height={20} className={'inline'} />&nbsp;<Translate text={"Weight"} />: {packageItem.weight || '?'} lbs
                                </div>
                                <div>
                                  <CreditCardIcon height={20} className={'inline'} />&nbsp;<Translate text={"Value"} />: <CurrencyText value={packageItem.value} />
                                </div>
                                <div>
                                  <CurrencyDollarIcon height={20} className={'inline'} />&nbsp;<Translate text={"Charges"} />: <CurrencyText value={packageItem.totalCharged} />
                                </div>
                                <div>
                                  {/*<CalendarDaysIcon height={20} className={'inline'} />&nbsp;<Translate text={"Received"} />:&nbsp;<DateText date={packageItem.receivedAt} />*/}
                                  <CurrencyDollarIcon height={20} className={'inline'} />&nbsp;<Translate text={"Balance"} />: <CurrencyText value={packageItem.totalBalance} />
                                </div>
                              </div>
                              <div className={'package-info-statuses'}>
                                {packageItem.invoiceStatus &&
                                    <>
                                      <span className={getStatusStyle('invoice', packageItem.invoiceStatus)}>{getStatusName('invoice', packageItem.invoiceStatus)}</span>
                                    </>
                                }
                                {packageItem.movementStatus &&
                                    <>
                                      <span className={getStatusStyle('movement', packageItem.movementStatus)}>{getStatusName('movement', packageItem.movementStatus)}</span>
                                    </>
                                }
                                {packageItem.paymentStatus &&
                                    <>
                                      <span className={getStatusStyle('payment', packageItem.paymentStatus)}>{getStatusName('payment', packageItem.paymentStatus)}</span>
                                    </>
                                }
                                {packageItem.etaStatus &&
                                    <>
                                      <span className={getStatusStyle('eta', packageItem.etaStatus)}>{getStatusName('eta', packageItem.etaStatus)}</span>
                                    </>
                                }
                              </div>
                            </div>
                          </div>
                      </Fragment>
                  ))}
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}