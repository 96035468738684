import Swal from 'sweetalert2';

export default args => {
    if ( !args ) return;
    let {type, text, title, buttonText} = args;
    let notification = {text};
    if ( title ) notification.title = title;
    notification.icon = type || 'info';
    notification.confirmButtonText = buttonText || 'OK';
    Swal.fire(notification);
}