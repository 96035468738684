import React, {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, Input, NumberText, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  InformationCircleIcon, PlusCircleIcon,
  ScaleIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon, XMarkIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import SettingsMenu from "../components/PaymentsMenu";
import PackagesMenu from "../components/PaymentsMenu";
import axios from "axios";
import PaymentsMenu from "../components/PaymentsMenu";
import { CheckIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/20/solid'
import NumberFormat from "react-number-format";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../components/CheckoutForm";
import "../../../../../styles/stripe.css";

export default function Transactions(props) {

  useDocumentTitle('Transactions');

  const [transactions, setTransactions] = useState([]);
  const [postingMode, setPostingMode] = useState(false);
  const [postingPage, setPostingPage] = useState('payment');
  const [paymentInfo, setPaymentInfo] = useState({});

  const [statement, setStatement] = useState({stats: []});

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    fetchStatement();
  }, []);

  const fetchStatement = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/statement`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setPaymentInfo({...paymentInfo, amount: response.body.statement?.payableBalanceJmd > 0 ? (Math.max(0, response.body.statement?.payableBalanceJmd || 0)).toFixed(2) : '', lynkId: response.body.lynkId || ""});
    setStatement(response.body.statement);
  }

  useEffect(() => {
    fetchTransactions();
    if ( props.deepLink === 'posting' ) {
      setPostingPage('payment');
      setPostingMode(true);
    } else if ( props.deepLink === 'card-payment-completed' ) {
      setPostingPage('cardPaymentSubmitted');
      setPostingMode(true);
    } else if ( props.deepLink === 'lynk-payment-completed' ) {
      setPostingPage('lynkPaymentSubmitted');
      setPostingMode(true);
    } else if ( props.deepLink === 'lynk-payment' ) {
      setPostingMode(true);
      setPostingPage('lynk-payment');  
    } else if ( props.deepLink === 'card-payment' ) {
      let serviceEndpointHost = window.getServiceEndpoint('');
      let url = `${serviceEndpointHost}/gateway/stripe/start`;
      fetch(url, {
        method: "POST",
        headers: window.getAxiosConfig()?.headers,
        body: JSON.stringify({ items: [{ id: "airship-package-deposit" }] }),
      })
        .then((res) => res.json())
        .then((data) => {
          if ( data.clientSecret ) {
            setClientSecret(data.clientSecret);
            localStorage.setItem('payment_intent_amount', data.paymentIntentAmount);
          } else {
            setNoSecret(true);
          }
        });
      setPostingPage('cardPayment');
      setPostingMode(true);
    }
  }, []);

  const fetchTransactions = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/transactions`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setTransactions(response.body.transactions);
    setPageLoaded(true);
  }

  const statusDefinitions = window.getConfig('STATUS_DEFINITIONS') || {};
  const getStatusStyle = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.style || `${status}`;
  }

  const getStatusName = (category, status) => {
    let defs = statusDefinitions[category] || '';
    let def = defs[status] || {};
    return def.label || `${status}`;
  }

  const startReceiptUpload = () => {
    setPostingMode(true);
  };

  const cancelReceiptUpload = () => {
    setPostingMode(false);
  };

  const savePaymentInfo = async () => {
    let body = paymentInfo;
    const response = await Http.request({uri: `/payments/post-transaction`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' && response.body.paymentState ) {
      localStorage.setItem('paymentState', response.body.paymentState);
      setPostingPage('receipt');
    }
  };

  const submitLynkPaymentRequest = async () => {
    let body = paymentInfo;
    const response = await Http.request({uri: `/payments/lynk/create`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      setPostingPage('lynkPaymentSubmitted');
    }
  };

  const handleChange = e => {
    let value = e.target.value;
    if ( e.target.name === 'lynkId' ) value = value.replace(/[^a-zA-Z0-9-_\.]/g, '');
    setPaymentInfo({...paymentInfo, [e.target.name]: value});
  };

  const selectFile = async e => {
    if ( e ) e.preventDefault();
    let fileList = e.target.files;
    let files = [];
    let fileCount = 1;
    for ( let key in fileList) {
      let file = fileList[key];
      if ( file.name && file.size && fileCount === 1 ) {
        fileCount++;
        files.push(file);
      }
    }

    const formData = new FormData();
    formData.append('receiptBinary', files[0]);
    formData.append('payload', JSON.stringify({}));

    await axios.post(window.getServiceEndpoint() + `/payments/${localStorage.getItem('paymentState')}/post-receipt`, formData, window.getAxiosConfig())
        .then(result => {
          console.log(result.data);
          if (typeof result.data !== 'undefined') {
          }
          if ( result.data.notification ) {
            Notifier(result.data.notification);
          }
          setPostingMode(false);
          fetchTransactions();
        }).catch(e => {
          if ( e.data.notification ) {
            Notifier(e.data.notification);
          }
        });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const transactionStatusColours = {
    completed: 'text-green-600',
    new: 'text-aqua-600',
    cancelled: 'text-black-700',
    reversed: 'text-red-600'
  };

  const TransactionIcon = props => <>
    {props.type === 'payment' && <CurrencyDollarIcon />}
    {props.type === 'reward' && <CurrencyDollarIcon />}
    {props.type === 'credit' && <CurrencyDollarIcon />}
    {props.type === 'debit' && <CurrencyDollarIcon />}
    {props.type === 'default' && <CurrencyDollarIcon />}
    {props.type === 'settlement' && <CurrencyDollarIcon />}
  </>;

  const TransactionInfo = props => {
    if ( props.transaction.type === 'payment' ) {
      return (
          <>
            <p className="text-sm">
              <Translate text={"Payment transaction"} />: <CurrencyText value={props.transaction.amount} /><br />
              <span className={'text-sm text-gray-500'}>{props.transaction.status}</span>
            </p>
          </>
      )
    }
    if ( props.transaction.type === 'credit' ) {
      return (
          <>
            <p className="text-sm">
              <Translate text={"Payment credit"} />: <CurrencyText value={props.transaction.amount} /><br />
              <span className={'text-sm text-gray-500'}>{props.transaction.status}</span>
            </p>
          </>
      )
    }
    if ( props.transaction.type === 'debit' ) {
      return (
          <>
            <p className="text-sm">
              <Translate text={"Payment debit"} />: <CurrencyText value={props.transaction.amount} /><br />
              <span className={'text-sm text-gray-500'}>{props.transaction.status}</span>
            </p>
          </>
      )
    }
    if ( props.transaction.type === 'reward' ) {
      return (
          <>
            <p className="text-sm">
              <Translate text={"Loyalty points rewarded"} />: <NumberText value={props.transaction.amount} /><br />
              <span className={'text-sm text-gray-500'}>{props.transaction.status}</span>
            </p>
          </>
      )
    }
    if ( props.transaction.type === 'redemption' ) {
      return (
          <>
            <p className="text-sm">
              <Translate text={"Loyalty points redeemed"} />: <CurrencyText value={props.transaction.amount} /><br />
              <span className={'text-sm text-gray-500'}>{props.transaction.status}</span>
            </p>
          </>
      )
    }
    if ( props.transaction.type === 'settlement' ) {
      return (
          <>
            <p className="text-sm">
              <Translate text={"Auto settlement"} />: <CurrencyText value={props.transaction.amount} /><br />
              <span className={'text-sm text-gray-500'}>{props.transaction.status}</span>
            </p>
          </>
      )
    }
  }

  const stripePromise = loadStripe(window.getConfig('STRIPE_PUBLIC_KEY'));
  const [clientSecret, setClientSecret] = useState("");
  const [noSecret, setNoSecret] = useState(false);

  const stripeFormAppearance = {
    theme: 'stripe',
  };
  const stripeFormOptions = {
    clientSecret,
    stripeFormAppearance,
  };

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Transactions"}></Header>
            <br />
            <div className={'text-center'}>
              {!postingMode && <span className={''}>
                <span className={'clickable rounded-md border border-transparent bg-lynk-inky-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-lynk-inky-black focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/pay-with-lynk')}><Translate text={"Pay with Lynk"} /></span>&nbsp;&middot;&nbsp;<span className={'clickable rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/card-payment')}><CreditCardIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Pay with Card"} /></span>&nbsp;&middot;&nbsp;<span className={'clickable rounded-md border border-transparent bg-orange-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-800 focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2 force-text-white'} onClick={() => setPostingMode(true)}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Post a Payment"} /></span>
              </span>}
              {postingMode && <span className={''}>
                  <span className={'clickable rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-yellow-900 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 force-text-white'} onClick={() => {setPostingMode(false); setPostingPage('payment')}}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Back to Transactions"} /></span>
              </span>}
            </div>
            <br />
            <Container>
              <div className="">

                <PaymentsMenu page={props.page || 'transactions'} />

                {!postingMode &&
                    <>

                      <div className="">

                          <div className="flow-root">
                            {!(transactions.length > 0) && pageLoaded &&
                                <div className={'text-center text-lg'}>
                                  <br />
                                  <InformationCircleIcon className={'text-red-500 inline'} height={'32'} /><br />
                                  <Translate text={"Not even ONE transaction nuh gwaan yet!"} />
                                </div>
                            }
                            {!pageLoaded &&
                                <div className={'text-center text-lg'}>
                                  <br />
                                  <i className="fas fa-spinner fa-spin"></i>
                                  <Translate text={"Looking for transactions. Please wait."} />
                                </div>
                            }
                            {transactions.length > 0 &&
                                <>
                                  <ul role="list" className="-mb-8">
                                    {transactions.map((transaction, eventIdx) => (
                                        <li key={transaction.referenceNumber}>
                                          <div className="relative pb-8">
                                            {eventIdx !== transactions.length - 1 ? (
                                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                      aria-hidden="true"/>
                                            ) : null}
                                            <div className="relative flex space-x-3">
                                              <div>
                <span
                    className={classNames(
                        transactionStatusColours[transaction.status],
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                    )}
                >
                  <TransactionIcon type={transaction.type || 'default'}/>
                </span>
                                              </div>
                                              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                <div>
                                                  <TransactionInfo transaction={transaction}/>
                                                </div>
                                                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                  <DateText value={transaction.createdAt}/>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                    ))}
                                  </ul>
                                </>
                            }
                          </div>

                      </div>

                    </>
                }

                {postingMode &&
                    <>
                      <div className={''}>

                        {postingPage === 'payment' &&
                            <>

                              <h2 className={'page-sub-title'}><Translate text={"Post a Payment"} /></h2><br />
                              <div className={''}>
                                <p className="">
                                  <Translate text={"If you've made a payment via Lynk or bank transfer, log it here so we can track and confirm it. Once confirmed, the total will be applied to your account."} />
                                </p>
                                <br />
                                <h2><strong><a href={"/payments/transactions/pay-with-lynk"} className={'rounded-md border border-transparent bg-lynk-inky-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-lynk-inky-black focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 link-white-text'}>Pay <em>directly</em> with Lynk</a></strong>{' or '}<strong><a href={"/payments/transactions/card-payment"} className={'rounded-md border border-transparent bg-indigo-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 link-white-text'}><Translate text={"Pay with a Card"} /></a></strong>{' '}<Translate text={"to cut out this middle step!"} /></h2>
                              </div>
                              <br/>
                              <hr/>
                              <br/>

                              <p>
                                <Translate text={"Please tell us about the payment you've made."} />
                              </p>

                              <br />

                              <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                                <div>
                                  <label htmlFor="value"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"How much did you pay?"}/>
                                  </label>
                                  <div className="mt-1">
                                    <Input
                                        id="value"
                                        name="value"
                                        type="number"
                                        className={"form-field-input"}
                                        value={paymentInfo.amount}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <label htmlFor="source"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Where did you pay it?"}/>
                                  </label>
                                  <div className="mt-1">
                                    <Input
                                        id="source"
                                        name="source"
                                        type="text"
                                        className={"form-field-input"}
                                        value={paymentInfo.source}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                              </div>

                              <br />

                              <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                                <div>
                                  <label htmlFor="value"
                                         className="block text-sm font-medium text-gray-700 form-field-label">
                                    <Translate text={"Reference number?"}/>
                                  </label>
                                  <div className="mt-1">
                                    <Input
                                        id="externalReferenceNumber"
                                        name="externalReferenceNumber"
                                        type="text"
                                        className={"form-field-input"}
                                        value={paymentInfo.externalReferenceNumber}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                              </div>

                              <br/>

                              <div>
                                <div>
                                  <button
                                      type="submit"
                                      className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      onClick={() => savePaymentInfo()}
                                  >
                                    <Translate text={"Continue"}/>
                                  </button>
                                </div>
                              </div>
                            </>
                        }

                        {postingPage === 'lynk-payment' &&
                            <>

                              <h2 className={'page-sub-title font-bold'}><Translate text={"Pay with Lynk & Earn Double Rewards"} /></h2><br />
                              <div className={''}>
                                <p className="">
                                  <Translate text={"In addition to your double rewards, you get a $500 discount for payments of $2,000 or more, a chance to win $50K weekly and a chance to win from the $1,000,000! grand prize, courtesy of Lynk."} />
                                </p>
                              </div>
                              <br/>
                              <hr/>
                              <br/>

                              <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                                <div>
                                  <label htmlFor="amount"
                                         className="block text-sm font-medium text-gray-900 form-field-label">
                                    <Translate text={"How much are you paying in JMD?"}/>
                                  </label>
                                  <div className="mt-1 mb-1 text-xs">Your total balance is now <CurrencyText value={statement?.payableBalanceJmd || 0} symbol="JMD" /></div>
                                  <div className="mt-5">
                                    <Input
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        className={"form-field-input"}
                                        value={paymentInfo.amount}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <label htmlFor="lynkId"
                                         className="block text-sm font-medium text-gray-900 form-field-label">
                                    <Translate text={"What's your Lynk ID?"}/>
                                  </label>
                                  <div className="mt-1 mb-1 text-xs">Same as your @handle, username, etc.</div>
                                  <div className="mt-5">
                                    <Input
                                        id="lynkId"
                                        name="lynkId"
                                        type="text"
                                        className={"form-field-input"}
                                        value={paymentInfo.lynkId}
                                        required={true}
                                        onChange={e => handleChange(e)}
                                    />
                                  </div>
                                </div>

                              </div>

                              <br/>

                              {paymentInfo.amount > 0 && <>
                                <div className={"p-5 text-sm border border-1 border-1-blue-500"}>
                                  We will submit a payment request for <strong><CurrencyText value={paymentInfo.amount} symbol={"JMD"} /></strong>{paymentInfo.lynkId ? <>&nbsp;to <strong>@{paymentInfo.lynkId || 'your Lynk ID'}</strong></> : ''}. You will be required to authorise the payment in the Lynk app. Keep your device close and log in.
                                </div>
                              
                                <br />

                                <div>
                                  <div>
                                    <button
                                        type="submit"
                                        className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => submitLynkPaymentRequest()}
                                    >
                                      <Translate text={"Continue"}/>
                                    </button>
                                  </div>
                                </div>
                                </>
                              }
                            </>
                        }

                        {postingPage === 'receipt' &&
                            <>
                              <p className={'text-xl text-green-700'}><Translate text={"Thanks for your payment!"} /></p>

                              <br />

                              <p className={'text-sm text-gray-700'}>
                                <Translate
                                    text={"If you have a receipt, upload below. It must be a PDF or image (JPG/JPEG/PNG) and 10 megs or less in size."}/>
                              </p>

                              <br/>

                              <label htmlFor={"receiptFileChooser"} className={'file-chooser-label'}><ArrowUpTrayIcon
                                  className={'inline'} height={"16"}/> <Translate text={"Select a file"}/></label>

                              <input type="file" id={"receiptFileChooser"} hidden onChange={selectFile}
                                     multiple={false}/>

                            </>
                        }

                        {postingPage === 'cardPayment' &&
                            <div className={'stripe'}>
                              {clientSecret && (
                                  <>
                                    <div className={'flex justify-center'}>
                                      <Elements options={stripeFormOptions} stripe={stripePromise}>
                                        <CheckoutForm />
                                      </Elements>
                                    </div>
                                  </>
                              )}
                              {noSecret && (
                                  <div className={"bg-orange-200 p-5 text-sm rounded shadow"}>
                                    <p className={'text-lg'}><Translate text={"Your balance is ZERO"} /></p>
                                    <br />
                                    <p><Translate text={"Sorry, we can't accept a debit/credit card payment right now."} /></p>
                                    <br />
                                    <p><Translate text={"If you had made a Lynk or bank transfer payment, we'd add the funds to your account as credit. This is however not allowed with card payments."} /></p>
                                  </div>
                              )}
                              <br />
                              <div className={"bg-green-300 p-5 text-sm rounded shadow mt-10"}>
                                <h1 className={'text-xl text-green-800'}><Translate text={"How We Process Card Payments:"} /></h1>
                                <ul className={'bullet-list mt-2'}>
                                  <li>
                                    <Translate text={"We use Stripe to process card payments through Wells Fargo (the processing bank)."} />
                                  </li>
                                  <li>
                                    <Translate text={"We do not collect or store any of your payment card information. When you type in your card details, they are automatically tokenised (encrypted) on your device before transmission and therefore cannot be stolen."} />
                                  </li>
                                  <li>
                                    <Translate text={"We orchestrate the payment flow, but your payment card information is sent directly to Stripe. They process the payment and then securely communicate the result to our systems, without human intervention."} />
                                  </li>
                                  <li>
                                    <Translate text={"NB: On your card statement or in your online bank account, the transaction most times will be labelled AIRSHIP. However, depending on the bank, it might use "} />{' '}<strong>C5-MARKET-LTD</strong>.{' '}<Translate text={"That's the name of our parent company."} />
                                  </li>
                                  <li>
                                    <Translate text={"The amount is charged in USD for your convenience. This means we use your own bank's exchange rate and not ours."} />
                                  </li>
                                  <li>
                                    <Translate text={"Read more about"} />{' '}<a href={"https://stripe.com/customers/all"} target={"_blank"}>Stripe</a>.
                                  </li>
                                </ul>
                              </div>

                            </div>
                        }

                        {postingPage === 'cardPaymentSubmitted' &&
                            <div>
                              <div className={"bg-green-200 p-10 text-md rounded shadow-white"}>
                                <h1 className={'text-xl text-green-800'}><Translate text={"Thank you!"} /></h1>
                                <br />
                                <p><Translate text={"Give us a few minutes to do some final checks on your card payment and then apply the amount to your account."} /></p>
                                <br />
                                <p><Translate text={"Please note: on your card statement or in your online bank account, the transaction will be labelled"} />{' '}<strong>C5-MARKETPLACE</strong>.{' '}<Translate text={"That's the name of our holding company."} /></p>
                                <br />
                              </div>
                            </div>
                        }

                        {postingPage === 'lynkPaymentSubmitted' &&
                            <div>
                              <div className={"border border-1-blue-200 p-10 text-md rounded shadow-white"}>
                                <h1 className={'text-xl text-indigo-800'}><Translate text={"Please authorise the Lynk payment"} /></h1>
                                <br />
                                <p><Translate text={"A payment request was submitted. You only need to authorise it from within the Lynk app."} /></p>
                                <br />
                                <p><strong><Translate text={"Make sure the request is coming from @AirShipCouriers (with a blue tick) and that it's for the correct amount."} /></strong></p>
                                <br />
                                <p><Translate text={"Once you authorise the payment, it will be applied to your account, and you will get your earned rewards 1 second later."} /></p>
                                <br />
                                <p><a href="/payments/transactions">I've authorised the payment</a></p>
                                <br />
                                
                              </div>
                            </div>
                        }

                      </div>
                    </>
                }

              </div>
            </Container>
          </div>
        </div>
      </>
  );
}