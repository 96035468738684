import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Container";
import Translate from "../../../../Utils/Translate";
import useDocumentTitle from "../../../../Utils/DocumentTitle";

export default function StartRecovery() {

  useDocumentTitle('Password Recovery');

  const [resetCodeRequest, setResetCodeRequest] = useState({});
  const [page, setPage] = useState('start');

  const handleChange = e =>{
    setResetCodeRequest({...resetCodeRequest, [e.target.name]: e.target.value});
  }

  const sendCode = async () => {
    let body = {...resetCodeRequest};
    const response = await Http.request({uri: '/auth/recovery/send-code', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.setItem('state', response.body.state);
      setPage('confirm');
      // window.location.assign(`/auth/recover/complete`);
    }
  }
  
  //////

  const [passwordInfo, setPasswordInfo] = useState({});

  const handleChangePassword = e => setPasswordInfo({...passwordInfo, [e.target.name]: e.target.value});

  const resetPassword = async () => {
    let body = {...passwordInfo, state: localStorage.state};
    const response = await Http.request({uri: `/auth/recovery/complete`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.clear();
      window.location.assign('/auth/login');
    }
  }
  
  //////

  const [confirmRequest, setConfirmRequest] = useState({});
  const handleChangeConfirm = e =>{
    setConfirmRequest({...confirmRequest, [e.target.name]: e.target.value});
  }

  const submitConfirmRequest = async () => {
    let body = {...confirmRequest, state: localStorage.getItem('state')};
    const response = await Http.request({uri: '/auth/recovery/confirm', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      setPage('password');
    }
  }

  const handleEnterKeySendCode = e => {
    if ( e.key === 'Enter' ) {
      sendCode();
    }
  }

  const handleEnterKeyConfirm = e => {
    if ( e.key === 'Enter' ) {
      submitConfirmRequest();
    }
  }

  const handleEnterKeyResetPassword = e => {
    if ( e.key === 'Enter' ) {
      resetPassword();
    }
  }

  return (
    <Fragment>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Password Reset"}></Header>
          <br />
          <Container size={'narrow'}>
            <div className="">

              {(page === 'start' || !page || page === '') &&
                  <>
                    <div>
                      <p className="mt-2 text-sm text-gray-600 text-center">
                        <Translate text={"We will help you get back access."} />&nbsp;
                        <Translate text={"If you know your credentials..."} />
                        <a href={"/auth/login"}><Translate text={"log in here"} /></a>.
                      </p>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <div className="mt-8" onKeyPress={e => handleEnterKeySendCode(e)}>
                      <div className="mt-6">
                        <div className="space-y-6">
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                              <Translate text={"What's your phone number (or email address)?"}/>
                            </label>
                            <div className="mt-1">
                              <Input
                                  id="userName"
                                  name="userName"
                                  type="userName"
                                  value={resetCodeRequest.userName}
                                  required={true}
                                  onChange={e => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div>
                            <button
                                type="submit"
                                className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                onClick={() => sendCode()}
                            >
                              <Translate text={"Continue"}/>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              }

              {page === 'confirm' &&
              <>
                <div className="mt-8" onKeyPress={e => handleEnterKeyConfirm(e)}>
                  <div className="mt-6">
                    <div className="space-y-6">
                      <div>
                        <label htmlFor="resetCode" className="block text-sm font-medium text-gray-700">
                          <Translate text={"What's the reset code?"} />
                        </label>
                        <div className="mt-1">
                          <Input
                              id="resetCode"
                              name="resetCode"
                              value={confirmRequest.resetCode}
                              type="text"
                              onChange={e => handleChangeConfirm(e)}
                          />
                        </div>
                      </div>

                      <div>
                        <button
                            type="submit"
                            className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => submitConfirmRequest()}
                        >
                          <Translate text={"Confirm Reset Code"} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              }
              
              {page === 'password' &&
              <>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  <Translate text={"Please set your new password."} />
                </label>
                <br />
                <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4" onKeyPress={e => handleEnterKeyResetPassword(e)}>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"New password"} />:
                    </label>
                    <div className="mt-1">
                      <Input
                          id="password"
                          name="password"
                          type="password"
                          className={"form-field-input"}
                          value={passwordInfo.password}
                          required={true}
                          onChange={e => handleChangePassword(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700 form-field-label">
                      <Translate text={"Confirm new password"} />:
                    </label>
                    <div className="mt-1">
                      <Input
                          id="passwordConfirm"
                          name="passwordConfirm"
                          type="password"
                          className={"form-field-input"}
                          value={passwordInfo.passwordConfirm}
                          required={true}
                          onChange={e => handleChangePassword(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                        type="submit"
                        className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => resetPassword()}
                    >
                      <Translate text={"Set New Password"} />
                    </button>
                  </div>

                </div>
              </>
              }
              
            </div>
          </Container>
        </div>
      </div>
    </Fragment>
  );

}