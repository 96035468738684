import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Container";
import Translate from "../../../../Utils/Translate";
import useDocumentTitle from "../../../../Utils/DocumentTitle";

export default function CompleteRecovery() {

  useDocumentTitle('Password Recovery');

  return (
      <Fragment>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Password Reset"}></Header>
            <br />
            <Container size={'narrow'}>
              <div className="">
                <div>
                  <p className="mt-2 text-sm">
                    <Translate text={"Please check your email."} />
                  </p>
                </div>

                <div className="mt-8">
                  <p className={'text-center'}>
                    <big>
                      <Translate text={"Your password has been reset."} />
                    </big>
                  </p>
                  <br />
                  <p>
                    <a href={"/auth/login"}><Translate text={"Proceed to Log In"} /></a>
                  </p>
                  <br /><br />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Fragment>
  );

}