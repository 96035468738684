import {Fragment, useEffect, useState} from "react";
import Header from "../../../Components/Header";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";


export default function Delivery(props) {

  useDocumentTitle('Delivery Information');

  useEffect(() => {
  }, []);

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Delivery"}></Header>
            <br />
            <Container>
              <div className="">

                <InfoMenu page={props.page || 'delivery'} />

                <h2 className={'page-sub-title'}>
                  <Translate text={"We Deliver"} />
                </h2>
                <br />
                <p><Translate text={"That sums it up."} /></p>
                <br />
                <p><Translate text={"For now, Members in and around Spanish Town, Portmore, Kingston and May Pen will get their packages delivered to their door."} /></p>
                <br />
                <p><Translate text={"We would like to deliver anywhere there, but"} />...<Translate text={"to be honest..."} /><strong><Translate text={"we're afraid of some places!"} /></strong>&nbsp;<Translate text={"That's because of bad roads 🙃."} />
                </p>
                <br />
                <p><><Translate text={"We'll let you know if we deliver to your specific address."} /></></p>

                <br /><hr /><br />

                <h2 className={'page-sub-title'}>
                  <Translate text={"How 'bout pickup?"} />
                </h2>
                <br />
                <p><Translate text={"Well, only if you're in May Pen."} /></p>
                <br />
                <p><Translate text={"You can pick up your packages at 2 West Park Avenue, May Pen, between 8:30 and 4 on weekdays (during the day 👀)."} /></p>
                <br />

              </div>

              <InfoFooter />

            </Container>
          </div>
        </div>

      </>
  );
}