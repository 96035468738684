import {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, NumberText, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  InformationCircleIcon, PlusCircleIcon,
  ScaleIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import SettingsMenu from "../components/PaymentsMenu";
import PackagesMenu from "../components/PaymentsMenu";
import axios from "axios";
import PaymentsMenu from "../components/PaymentsMenu";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

export default function Rewards(props) {

  useDocumentTitle('Reward Points');

  const [points, setPoints] = useState({pointsValue: 0, cashValue: 0});

  useEffect(() => {
    fetchPoints();
  }, []);

  const fetchPoints = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/points`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setPoints(response.body.points);
  }

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Reward Points"}></Header>
            <br />
            <Container>
              <div className="">

                <PaymentsMenu page={props.page || 'rewards'} />

                <div className={'text-center'}>
                  <span className="inline-flex items-center rounded-full bg-blue-400 px-2.5 py-0.5 text-xl font-medium text-white p-5 text-xl">
                    <NumberText value={points.pointsValue} />&nbsp;<Translate text={'points'} />
                  </span>
                  <br /><br />
                  <p className={'text-center text-gray-500'}>
                    <Translate text={"This is worth"} />: {points.cashValue > 0 ? <CurrencyText value={points.cashValue} /> : <><Translate text={"guess how much?"} />&nbsp;🥲</>}
                  </p>
                </div>

                <br />
                <hr />
                <br />

                <div className={''}>
                  <p><strong><Translate text={"What's this?"} /></strong></p><br />
                  <p><Translate text={"In the kind spirit of giving back and showing appreciation, we reward you with loyalty points each time you ship and pay for a package."} /></p>
                  <br />
                  <p><span className={'text-green-600'}>
                    <Translate text={"If you pay with Lynk, your reward points DOUBLE!"} /></span>
                  </p><br />
                  <p><Translate text={"More info"} />: <a href={"/info/payments"} className={"text-green-600"}><Translate text={"How do I pay with Lynk?"} /></a>
                  </p>
                  <br />
                  <hr />
                  <br />
                  <p><strong><Translate text={"How do we calculate points?"} /></strong></p><br />
                  <p><Translate text={"For each pound of package shipped, you earn 100 points, when you pay for the package. That makes it 200 points, if you pay with Lynk."} /></p>
                  <br />
                  <p><strong><Translate text={"How do I use my points?"} /></strong></p><br />
                  <p><Translate text={"You can redeem your earned points and pay for packages."} /></p>
                  <br />
                  <p><strong><Translate text={"What's the cash value of my points?"} /></strong></p><br />
                  <p><Translate text={"You get the value of J$1,000 for every 10,000 points. That's J$100 for every 1,000 points. If you have 1 million points, that's worth a Yeng Yeng bike."} /></p>
                  <br />
                  <p><strong><Translate text={"Can I convert my points to cash and receive a payment?"} /></strong></p><br />
                  <p><Translate text={"Lol! We're not a bank. We just love you as a Member, so we created an instrument to reward you for your loyalty."} /></p>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}