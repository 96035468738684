import {Fragment, useEffect, useState} from "react";
import Header from "../../../Components/Header";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";


export default function HowItWorks(props) {

  useDocumentTitle('How it Works');

  useEffect(() => {
  }, []);

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"How it Works"}></Header>
            <br />
            <Container>
              <div className="">

                <InfoMenu page={props.page || 'how-it-works'} />

                <h2 className={'page-sub-title'}>
                  <Translate text={"Welcome to the club!"} />
                </h2>
                <br />
                <p>
                  <strong><Translate text={"We're not just shippers."} /></strong>
                </p>
                <br />
                <p><Translate text={"It's a club. With your membership: among other cool benefits, you get a US address to which you ship your online orders. We then jet lift them to Jamaica, clear and take them to you, at your own convenience."} /></p>
                <br />
                <p><Translate text={"Each time you ship a package, we reward you with points that you can redeem, and you advance along the membership journey."} /></p>

                <br />
                <p><strong><Translate text={"What do Club Members get?"} /></strong></p>
                <br />
                <p><Translate text={"You mean, what MORE? How about ... birthday gifts? 🎉 😁"} />&nbsp;<Translate text={"We have other cool features lined up such as one that allows you to surprise someone with a gift, using yours or their AirShip box."} /></p>

                <br /><hr /><br />

                <h2 className={'page-sub-title'}><Translate text={"The Journey of a Package"} /></h2>
                <br />
                <p><Translate text={"Packages are like elite travellers with diplomatic visas. In the graphic below, we describe the exciting journey of a package starting with you. You anxiously order it, it takes off on a first class jet flight from an airport in Florida, and this journey ends with a climax when you receive it in your hands."} /></p>
                <br />
                <p>
                  <img alt={"The journey of a package."} src={"/assets/images/airship_couriers_journey_of_a_package.png"} className={'rounded-lg shadow-white'} />
                </p>
                <br />
                <h2 className={'page-sub-title'}><Translate text={"You're most welcome."} /></h2>
                <br />
                <p>&mdash; <a href={"/info/rates"}>View our rates here</a></p>
                <p>&mdash; <a href={"/info/payments"}>Learn how to pay</a></p>
                <p>&mdash; <a href={"/info/delivery"}>Learn about delivery</a></p>
              </div>
              <InfoFooter />
            </Container>
          </div>
        </div>

      </>
  );
}