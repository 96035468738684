import Translate from "../Utils/Translate";

export default props => {
    return (
      <>
          <div className="relative">
              <div className="relative flex justify-center">
                  <h1 className="text-lg font-medium page-title shadow-white font-bold">
                      <Translate text={props.title} />
                  </h1>
              </div>
          </div>
      </>
    );
  }