import {Fragment, useEffect, useState} from "react";
import Header from "../../../Components/Header";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";
import {BoltIcon, DevicePhoneMobileIcon, GlobeAltIcon, ScaleIcon} from "@heroicons/react/20/solid";


export default function Home(props) {

  useDocumentTitle('Join the Club and Earn 500 Points');

  useEffect(() => {
  }, []);

  const features = [
    {
      name: 'Competitive rates',
      description:
          "Although we don't aim to be the cheapest on the block, we offer great service at extremely competitive rates.",
      icon: GlobeAltIcon,
    },
    {
      name: 'No handling fees',
      description:
          "Your charges are fair and transparent, and we never impose random additional fees and give them fancy names.",
      icon: ScaleIcon,
    },
    {
      name: 'Shipping is fast',
      description:
          "#Facts. Don't believe us? Send your first package to our FL address and track it in the app. Don't take our word.",
      icon: BoltIcon,
    },
    {
      name: 'Stay up-to-date',
      description:
          "We will keep you notified as your package arrives in FL, takes off on the jet and finally when it's ready to see you.",
      icon: DevicePhoneMobileIcon,
    },
  ]

  return (
      <>
        <div className="isolate">
          <main>
            <div className="relative px-6 lg:px-8">
              <div className="mx-auto max-w-3xl pt-10 pb-32 sm:pt-28 sm:pb-20">
                <div>
                  <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                    <div className="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span className="text-gray-600">
                    <Translate text={"Learn everything about AirShip in 2 minutes."} />{' '}
                    <a href="/info/how-it-works" className="font-semibold text-indigo-600">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <Translate text={"Start Here"} /> <span aria-hidden="true">&rarr;</span>
                    </a>
                  </span>
                    </div>
                  </div>
                  <div>
                    <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                      <Translate text={"We're not just shippers."} />
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600 sm:text-center">
                      <strong><Translate text={"We ship fast at great rates, but you can take that for granted."} /></strong><br />
                      <Translate text={"Join our Membership Club and take advantage of the cool benefits of being part of a value-creating community."} />
                    </p>
                    <div className="mt-8 flex gap-x-4 sm:justify-center">
                      <a
                          href="/auth/register"
                          className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                      >
                        <Translate text={"Register"} />{' '}
                        <span className="text-indigo-200" aria-hidden="true">
                      </span>
                      </a>
                      <a
                          href="/auth/login"
                          className="inline-block rounded-lg bg-yellow-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-yellow-700 hover:ring-indigo-700"
                      >
                        <Translate text={"Log In"} />{' '}
                        <span className="text-indigo-200" aria-hidden="true">
                      </span>
                      </a>
                    </div>
                  </div>
                  <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                          fillOpacity=".3"
                          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                      />
                      <defs>
                        <linearGradient
                            id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                            x1="1155.49"
                            x2="-78.208"
                            y1=".177"
                            y2="474.645"
                            gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#89c6fc" />
                          <stop offset={1} stopColor="#b8d3e3" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="mx-auto max-w-7xl px-6 lg:px-8 bg-white p-10">
              <div className="sm:text-center">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  <Translate text={"Still, a better way to ship your packages."} />
                </p>
                <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
                  <Translate text={"Choosing AirShip is not near as hard as figuring out what to wear."} />
                </p>
              </div>

              <br />

              <hr />

              <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
                <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
                  {features.map((feature) => (
                      <div key={feature.name} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                        <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white sm:shrink-0">
                          <feature.icon className="h-8 w-8" aria-hidden="true" />
                        </div>
                        <div className="sm:min-w-0 sm:flex-1">
                          <p className="text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                          <p className="mt-2 text-base leading-7 text-gray-600">{feature.description}</p>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
        </div>

        <div className="bg-white">
          <div className="">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1024 1024"
                  className="hidden absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2 z-index-999"
                  aria-hidden="true"
              >
                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                  <radialGradient
                      id="759c1415-0410-454c-8f7c-9a820de03641"
                      cx={0}
                      cy={0}
                      r={1}
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(512 512) rotate(90) scale(512)"
                  >
                    <stop stopColor="#26b8e0" />
                    <stop offset={1} stopColor="#FFFFFF" stopOpacity={0} />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  <Translate text={"Your first shipment each month is FREE."} /><sup>*</sup>
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-300 z-index-top">
                  <Translate text={"Become an early Member and keep the royal benefits for a lifetime."} />
                  <br />
                  <span style={{fontSize: "50%"}}><sup>*</sup> conditions apply</span>
                  <br />
                  <a
                      href="/auth/register"
                      className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                  >
                    <Translate text={"Register"} />{' '}
                    <span className="text-indigo-200" aria-hidden="true">
                      </span>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                      href="/auth/login"
                      className="inline-block rounded-lg bg-yellow-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-yellow-700 hover:ring-indigo-700"
                  >
                    <Translate text={"Log In"} />{' '}
                    <span className="text-indigo-200" aria-hidden="true">
                      </span>
                  </a>
                </p>
              </div>
              <div className="relative mt-16 h-80 lg:mt-8">
                <img
                    className="absolute top-0 left-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                    src="/assets/images/i-love-freeness.png"
                    alt="I love freeness!"
                    width={800}
                    height={500}
                />
              </div>
            </div>
          </div>
        </div>

      </>
  );
}