import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import Translate from "../../../../../Utils/Translate";
import {CurrencyText} from "../../../../../Utils/Template";

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState(`box+${localStorage.getItem('boxNumber')}@airshipcouriers.com`);
    const [billingDetails, setBillingDetails] = useState({name: `${localStorage.getItem('userName')} - #${localStorage.getItem('boxNumber')}`});
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        let confirmParams = {
            // Make sure to change this to your payment completion page
            return_url: `${window.getServiceEndpoint('')}/public/gateway_response/${window.btoa(JSON.stringify({box_number: localStorage.getItem('boxNumber'),access_token: localStorage.getItem('access_token')}))}/post`,
        };
        if ( email ) confirmParams.receipt_email = email;

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams,
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
        // defaultValues: {billingDetails}
    }

    const linkAuthenticationElementOptions = {
        defaultValues: {email}
    }

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>
                <div className={'text-lg'}>
                    <strong><Translate text={"Amount to pay"} />: <CurrencyText value={localStorage.getItem('payment_intent_amount')} /></strong>
                </div>
                <br />
                <hr />
                <br />
                <div className={'hidden'}><LinkAuthenticationElement
                    id="link-authentication-element"
                    options={linkAuthenticationElementOptions}
                /></div>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
        </>
    );
}