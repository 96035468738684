import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Browse from "./pages/Browse";
import PreRegister from "./pages/PreRegister";

export default function Main() {
    return (
      <main>
        <Routes>
            <Route path="/delivered" element={<Browse mode={"delivered"} />} />
            <Route path="/inactive" element={<Browse mode={"inactive"} />} />
            <Route path="/new" element={<Browse mode={"new"} />} />
            <Route path="/pre-register" element={<PreRegister />} />
            <Route path="*" element={<Browse mode={"active"} />} />
        </Routes>
      </main>
    );
  }