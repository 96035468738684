export default props => {
    return (
      <>
          {props.size === 'narrow' &&
              <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="main-container shadow mx-auto max-w-xl px-4 sm:px-6 lg:px-8">
                      <br/><br/>
                      {props.children}
                      <br/><br/>
                  </div>
              </div>
          }
          {props.size === '' || !props.size &&
              <div className="container mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
                  <div className="main-container shadow mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
                      <br /><br />
                      {props.children}
                      <br /><br />
                  </div>
              </div>
          }
      </>
    );
  }