import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { VerificationSession, Welcome } from 'cleared-verification-client';
import 'cleared-verification-client/dist/styles/no-tailwind.css';
import Translate from "../../../../../Utils/Translate";
import moment from "moment";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Verification() {

  useDocumentTitle('Verification Centre');

  const [authToken, setAuthToken] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getUserToken();
  }, []);

  const getUserToken = async () => {
    let body = {};
    const response = await Http.request({ uri: `/session/cleared-auth-token`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      setAuthToken(response.body.token);
      setCustomerId(response.body.customerId);
      setEmailAddress(response.body.emailAddress);
      setFirstName(response.body.firstName);
      setLastName(response.body.lastName);
    }
    setLoaded(true);
  }


  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Verification Centre"}></Header>
          <br />
          <Container>
            <div className="">

              <p>
                <Translate text={"We are required to validate your identity for our records, as we clear packages on your behalf. This is our Verification Centre, a convenient online tool that you can use to verify your ID and stay KYC-compliant."} />
              </p>

              <br />
              <hr />
              <br />

              {authToken && customerId && <>
                <div className='mt-5 w-full'>
                  <VerificationSession authToken={authToken} services={['identity']} customerReferenceId={customerId} customerData={{ emailAddress, firstName, lastName }} />
                </div>
              </>}
              {loaded && !authToken &&
                <div className='mt-5 w-full'>
                  <Welcome customerReferenceId={customerId} customerData={{ emailAddress, firstName, lastName }} />
                </div>
              }


            </div>
          </Container>
        </div>
      </div>
    </>
  );
}