import {Fragment, useEffect, useState} from "react";
import Header from "../../../Components/Header";

import Container from "../../../Components/Container";
import InfoMenu from "../components/InfoMenu";
import Translate from "../../../Utils/Translate";
import useDocumentTitle from "../../../Utils/DocumentTitle";
import InfoFooter from "../components/InfoFooter";
import moment from "moment/moment";
import {Input} from "../../../Utils/Template";
import Http from "../../../Utils/Http";
import Notifier from "../../../Utils/Notifier";
import {useParams} from "react-router-dom";


export default function PromoFollow(props) {

  useDocumentTitle('Follow Us and Earn Points');

  const {resultType} = useParams();

  const [promo, setPromo] = useState({
    active: true,
    balance: 50,
    twitterBalance: 30,
    instagramBalance: 20,
    startDate: moment('June 17, 2023')
  });

  useEffect(() => {
    updateBalance();
  }, [])

  const updateBalance = () => {
    let timeElapsed = moment.unix(moment()).diff(moment.unix(promo.startDate), 'seconds');
    let cents = timeElapsed / 100000000;
    let balance = 50 - cents;
    setPromo({...promo, balance, twitterBalance: balance * (3/5), instagramBalance: balance * (2/5)});
    setTimeout(() => updateBalance(), 50);
  }

  const twitterAuthorise = async () => {
    let response = await Http.request({uri: `/auth/twitter/start`});
    if (response.notification) {
      Notifier(response.notification);
    }
    console.log(response.body.authUrl)
   if ( response.body.authUrl ) window.location.assign(response.body.authUrl);
  };

  const isLoggedIn = (localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client');

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Social Media Promo"}></Header>
            <br />
            <Container>
              <div className="">

                {['thank-you'].includes(resultType) && isLoggedIn &&
                    <>

                      <h2 className={'page-sub-title'}>
                        <Translate text={"Thank You!"}/>
                      </h2>
                      <br/>
                      <p>
                        <Translate
                          text={"We appreciate your engagement and support. It means A LOT. If you haven't yet done so, please follow our page and stay tuned for updates and more niceness!"} />
                      </p>
                      <br />
                      <p>
                        Good news -- your account was just credited. Go to <a href={"/payments"}>Payments</a> and see your account net balance, and you should also see a transaction with a credit posted to your account."}/>
                      </p>
                      <br />
                      <div className={'mt-5 p-5 mb-5 bg-yellow-200'}>
                        <p>As promised, use this link: <a href={"https://twitter.com/settings/connected_apps/27330675"}>https://twitter.com/settings/connected_apps/27330675</a> to manage the access that AirShip Couriers has to your Twitter account.</p>
                        <br />
                        <p>We don't need anymore access for now, so you can revoke the permissions.</p>
                      </div>
                    </>
                }

                {['error'].includes(resultType) && isLoggedIn &&
                    <>

                      <div className={'mt-10 mb-10 bg-red-300 text-red-900'}>
                        <p>
                          <Translate
                          text={"Something went wrong. We requested your authorisation on Twitter, but it failed. Please try again."}/>
                        </p>

                      </div>

                    </>
                }

                {( !['thank-you'].includes(resultType) || !isLoggedIn ) &&
                    <>

                      <h2 className={'page-sub-title'}>
                        <Translate text={"Sh_ring is C_ring!"}/>
                      </h2>
                      <br/>
                      <p>
                        <Translate
                          text={"We're new and growing, and we're sharing rewards with Members who follow us on social media. That's it really."}/>
                      </p>
                      <br/>
                      <p>It's <strong>@AirShipCouriers</strong> on <a
                          href={"https://twitter.com/AirShipCouriers"}>Twitter</a> and <a
                          href={"https://instagram.com/AirShipCouriers"}>Instagram</a>. <Translate
                          text={"Follow our pages and come back here to authorise a Tweet from AirShip. We'll credit your AirShip account immediately."}/>
                      </p>
                      <br/>

                      <table className="min-w-full divide-y divide-gray-300">
                        <tbody className="divide-y divide-gray-200 bg-white">
                        <tr>
                          <td className="whitespace-nowrap px-3 py-4 text-sm">
                            <>Rewards for engagement on Twitter</>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-green-800 font-bold text-fixed">
                            <span className={'balance'}>US${promo.twitterBalance.toFixed(6)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="whitespace-nowrap px-3 py-4 text-sm">
                            <>Rewards for engagement on Instagram</>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-green-800 font-bold text-fixed">
                            <span className={'balance'}>US${promo.instagramBalance.toFixed(6)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="whitespace-nowrap px-3 py-4 text-sm">
                            <>Rewards for engaging on both</>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-green-800 font-bold text-fixed">
                            <span className={'balance'}>US${promo.balance.toFixed(6)}</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>


                      <br/>
                      <p>
                        <button onClick={() => window.location.assign("/auth/login?goto=/info/promo-follow")}
                                className={"rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 primary-btn"}>Log
                          in
                        </button>
                        &nbsp;&nbsp;or&nbsp;&nbsp;
                        <button onClick={() => window.location.assign("/auth/register?goto=/info/promo-follow")}
                                className={"rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 primary-btn"}>Register
                        </button>
                        &nbsp;&nbsp;to claim your reward
                      </p>

                      {isLoggedIn &&
                          <>
                            <div className={'mt-10'}>
                              <p>Are you ready to start? If you select yes, we'll send you to Twitter to authorise us.
                                Once
                                authorised, we will post the following:</p>
                              <div className={'p-5 bg-blue-200 text-md text-pre mb-10 mt-5 text-blue-900'}>
                                I just joined the @AirShipCouriers shipping club. I'll be air shipping my packages from
                                the US
                                to Jamaica with first class tickets on the AirShip jet! Join me in the club and earn
                                $50.
                                #AirShipCouriers #ShipToJamaica
                              </div>
                              <div className={'p-5 bg-orange-200 text-md mb-5 mt-5'}>
                                NB: We only need this access once. When you're through, it's recommended that you revoke
                                access to your Twitter account by our app, unless you need to use it in the future
                                again. After authorising the tweet, we'll send you a link to manage the access this app
                                has to your Twitter account.
                              </div>
                              <button onClick={() => twitterAuthorise()}
                                      className={"rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 primary-btn"}>Go
                                to Twitter
                              </button>
                            </div>
                          </>
                      }

                    </>
                }

              </div>

            </Container>
          </div>
        </div>

      </>
  );
}