import {InformationCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import Translate from "../../../Utils/Translate";

export default props => {
    return (
        <>
            {[null, undefined, ''].includes(localStorage.getItem('access_token')) &&
                <div className={'text-center text-lg'}>
                    <br />
                    <hr />
                    <br />
                    <a href={"/auth/register"} className={'clickable rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 force-text-white'}><Translate text={"It all starts here. Register Now."} /></a>
                </div>
            }
        </>
    )
}