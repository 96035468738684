import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { Input } from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import SettingsMenu from "../components/SettingsMenu";
import Translate from "../../../../../Utils/Translate";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ChangePassword() {

  useDocumentTitle('Change Password');

  const [passwordInfo, setPasswordInfo] = useState({});
  const [showFunFact, setShowFunFact] = useState(false);

  const handleChange = e => setPasswordInfo({ ...passwordInfo, [e.target.name]: e.target.value });

  const changePassword = async () => {
    let body = passwordInfo;
    const response = await Http.request({ uri: `/account/password`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      changePassword();
    }
  }

  return (
    <>

      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Change Password"}></Header>
          <br />
          <Container>
            <div className="">

              <SettingsMenu page={'password'} />

              <div className="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4" onKeyPress={e => handleEnterKey(e)}>
                <div>
                  <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700 form-field-label">
                    <Translate text={"Current password"} />:
                  </label>
                  <div className="mt-1">
                    <Input
                      id="currentPassword"
                      name="currentPassword"
                      type="password"
                      className={"form-field-input"}
                      value={passwordInfo.currentPassword}
                      required={true}
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <br />

              <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 form-field-label">
                    <Translate text={"New password"} />:
                  </label>
                  <div className="mt-1">
                    <Input
                      id="password"
                      name="password"
                      type="password"
                      className={"form-field-input"}
                      value={passwordInfo.password}
                      required={true}
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="passwordConfirm" className="block text-sm font-medium text-gray-700 form-field-label">
                    <Translate text={"Confirm new password"} />:
                  </label>
                  <div className="mt-1">
                    <Input
                      id="passwordConfirm"
                      name="passwordConfirm"
                      type="password"
                      className={"form-field-input"}
                      value={passwordInfo.passwordConfirm}
                      required={true}
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </div>

              </div>

              <br />

              <div>
                {!showFunFact && <strong className={'clickable underline text-blue-600'} onClick={() => setShowFunFact(true)}>Fun fact...anyone?</strong>}
                {showFunFact && <>
                  <div className={"bg-orange-300 p-5 text-sm rounded shadow"}>
                    Okay...so, first it's a reminder that all of your details are safe with us, and importantly, <strong>nobody</strong> knows your password. The fun fact is that, this is what your password looks like in our database, after we encrypt it: <em>$2a$10$Z32X6vhUpl9Hgehhe...</em> Perhaps only Einstein could guess what it represents; only that he's dead.
                  </div>
                </>}
              </div>

              <br />

              <div>
                <button
                  type="submit"
                  className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={() => changePassword()}
                >
                  <Translate text={"Change Password"} />
                </button>
              </div>

            </div>
          </Container>
        </div>
      </div>

    </>
  );
}