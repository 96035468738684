import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import StartRecovery from "./pages/StartRecovery";
import CompleteRecovery from "./pages/CompleteRecovery";
import App from "../../../App";

export default function Recover() {
    return (
      <Routes>
        <Route path="/" element={<StartRecovery />} />
        <Route path="/start" element={<StartRecovery />} />
        <Route path="/complete" element={<CompleteRecovery />} />
      </Routes>
    );
  }