import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { Input } from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import SettingsMenu from "../components/SettingsMenu";
import Translate from "../../../../../Utils/Translate";
import moment from "moment";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Notifications() {

  useDocumentTitle('Notifications Preferences');

  const [notificationPreferences, setNotificationPreferences] = useState({
    inflight_sms: false,
    inflight_email: false,
    customs_sms: false,
    customs_email: false,
    ready_sms: false,
    ready_email: false
  });

  const handleChange = e => {
    let value = e.target.value;
    if (value === 'true') value = false;
    else value = true;
    setNotificationPreferences({ ...notificationPreferences, [e.target.name]: value });
  }

  useEffect(() => {
    fetchNotificationPreferences();
  }, []);

  const fetchNotificationPreferences = async () => {
    let body = {};
    const response = await Http.request({ uri: `/account/get-notification-preferences`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    setNotificationPreferences(response.body)
  }

  const saveNotificationPreferences = async () => {
    let body = notificationPreferences;
    const response = await Http.request({ uri: `/account/patch/notification-preferences`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      saveNotificationPreferences();
    }
  }

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Notifications"}></Header>
          <br />
          <Container>
            <div className="" onKeyPress={e => handleEnterKey(e)}>

              <SettingsMenu page={'notifications'} />

              <p>
                <Translate text={"As your package travels, we'll send you notifications via email, SMS, or both. These are free. You choose."} />
              </p>

              {notificationPreferences.hasEmailOnly && <><br /><div className={'bg-orange-200 p-5 text-sm'}><Translate text={"We noticed you only have an email address on record, with no phone number. Even if you activate SMS notifications ... ... I guess you can finish that sentence"} />😛</div></>}

              {notificationPreferences.hasSmsOnly && <><br /><div className={'bg-orange-200 p-5 text-sm'}><Translate text={"We noticed you only have a phone number on record, with no email address. Even if you activate email notifications ... ... I guess you can finish that sentence"} />😛</div></>}

              <br />
              <hr />
              <br />

              <div className="">

                <p>
                  <strong>Take Off</strong>
                  <br />
                  <small><Translate text={"We'll notify you when your package takes off on the jet, or has secured a ticket for the next flight."} /></small>
                </p>
                <br />
                <div className="">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="inflight_email"
                        aria-describedby="inflight_email-description"
                        name="inflight_email"
                        value={`${notificationPreferences.inflight_email}`}
                        checked={notificationPreferences.inflight_email}
                        onChange={e => handleChange(e)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="inflight_email" className="font-medium text-gray-900">
                        Email
                      </label>{' '}
                      <span id="inflight_email-description" className="text-gray-500">
                        <span className="sr-only">Email </span>me when it gets a flight
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="inflight_sms"
                        aria-describedby="inflight_sms-description"
                        name="inflight_sms"
                        value={`${notificationPreferences.inflight_sms}`}
                        checked={notificationPreferences.inflight_sms}
                        onChange={e => handleChange(e)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="inflight_sms" className="font-medium text-gray-900">
                        Text
                      </label>{' '}
                      <span id="inflight_sms-description" className="text-gray-500">
                        <span className="sr-only">Text </span>me when it gets a flight
                      </span>
                    </div>
                  </div>
                </div>

                <br />
                <hr />
                <br />

                <p>
                  <strong>Clearance</strong>
                  <br />
                  <small><Translate text={"We'll notify you when your package lands, disembarks and is being cleared through Customs."} /></small>
                </p>
                <br />
                <div className="">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="customs_email"
                        aria-describedby="customs_email-description"
                        name="customs_email"
                        value={`${notificationPreferences.customs_email}`}
                        checked={notificationPreferences.customs_email}
                        onChange={e => handleChange(e)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="customs_email" className="font-medium text-gray-900">
                        Email
                      </label>{' '}
                      <span id="customs_email-description" className="text-gray-500">
                        <span className="sr-only">Email </span>me when it lands
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="customs_sms"
                        aria-describedby="customs_sms-description"
                        name="customs_sms"
                        value={`${notificationPreferences.customs_sms}`}
                        checked={notificationPreferences.customs_sms}
                        onChange={e => handleChange(e)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="customs_sms" className="font-medium text-gray-900">
                        Text
                      </label>{' '}
                      <span id="customs_sms-description" className="text-gray-500">
                        <span className="sr-only">Text </span>me when it lands
                      </span>
                    </div>
                  </div>
                </div>

                <br />
                <hr />
                <br />

                <p>
                  <strong>Ready</strong>
                  <br />
                  <small><Translate text={"We'll notify you when your package passes through Customs and is finally ready."} /></small>
                </p>
                <br />
                <div className="">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="ready_email"
                        aria-describedby="ready_email-description"
                        name="ready_email"
                        value={`${notificationPreferences.ready_email}`}
                        checked={notificationPreferences.ready_email}
                        onChange={e => handleChange(e)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="ready_email" className="font-medium text-gray-900">
                        Email
                      </label>{' '}
                      <span id="ready_email-description" className="text-gray-500">
                        <span className="sr-only">Email </span>me when it's ready
                      </span>
                    </div>
                  </div>
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id="ready_sms"
                        aria-describedby="ready_sms-description"
                        name="ready_sms"
                        value={`${notificationPreferences.ready_sms}`}
                        checked={notificationPreferences.ready_sms}
                        onChange={e => handleChange(e)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor="ready_sms" className="font-medium text-gray-900">
                        Text
                      </label>{' '}
                      <span id="ready_sms-description" className="text-gray-500">
                        <span className="sr-only">Text </span>me when it's ready
                      </span>
                    </div>
                  </div>
                </div>

              </div>

              <br />

              <div>
                <div>
                  <button
                    type="submit"
                    className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => saveNotificationPreferences()}
                  >
                    <Translate text={"Save my Preferences!"} />
                  </button>
                </div>

                {(notificationPreferences.meta?.disableEmail || notificationPreferences.meta?.disablePhoneNumber) &&
                  <>
                    <br />
                    <hr />
                    <br />

                    <p className={'text-sm text-gray-500'}><InformationCircleIcon className={'inline clickable'} height={"18"} />&nbsp;We understand it might be a little inconvenient, but this {notificationPreferences.meta?.disableEmail && <>email address</>}&nbsp;{notificationPreferences.meta?.disablePhoneNumber && <>phone number</>} has been verified so you cannot change it now. We will create a feature to allow changing and re-verifying your details. It's for your protection and others', so people don't steal identities. We'll fix it soon!
                    </p>
                  </>
                }

              </div>

            </div>
          </Container>
        </div>
      </div>
    </>
  );
}