import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

import StartRegistration from "./pages/StartRegistration";
import ConfirmEmail from "./pages/ConfirmEmail";
import {useEffect} from "react";

  export default function Register() {
      useEffect(() => {
          if ( localStorage.getItem('access_token') ) {
              window.location.assign('/packages');
          }
      }, []);
      return (
        <Routes>
          <Route path="/" element={<StartRegistration />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
        </Routes>
      );
    }