import {Fragment, useEffect, useState} from "react";
import Http from "../../Utils/Http";
import Notifier from "../../Utils/Notifier";

import Template, { Input } from "../../Utils/Template";
import Header from "../../Components/Header";
import Container from "../../Components/Container";
import Translate from "../../Utils/Translate";
import useDocumentTitle from "../../Utils/DocumentTitle";

export default function Login() {

  useDocumentTitle('Login');

  useEffect(() => {
    if ( localStorage.getItem('userName') ) {
      setStaleSession(true);
      localStorage.removeItem('userName');
      localStorage.removeItem('access_token');
      localStorage.removeItem('userEmail');
      localStorage.removeItem('boxNumber');
    }
  }, []);

  const [credentials, setCredentials] = useState({});
  const [staleSession, setStaleSession] = useState(false);

  const handleChange = e =>{
    setCredentials({...credentials, [e.target.name]: e.target.value});
  }

  const submitLogin = async () => {
    let body = {...credentials};
    const response = await Http.request({uri: '/auth/login', body});
    if (response.notification) {
      Notifier(response.notification);
    }
    if ( response.result === 'success' ) {
      localStorage.setItem('access_token', response.body.access_token);
      localStorage.setItem('loginType', response.body.loginType);
      localStorage.setItem('userEmail', response.body.userEmail);
      localStorage.setItem('userName', response.body.userName);
      localStorage.setItem('boxNumber', response.body.boxNumber);
      if (response.body.enableVerificationFeature) {
        localStorage.setItem('VERIFICATION_FEATURE', "on");
      } else {
        localStorage.removeItem('VERIFICATION_FEATURE');
      }
      let targetPage = localStorage.getItem('targetPage') || '/packages';
      if ( targetPage ) {
        localStorage.removeItem('targetPage');
        if ( !targetPage || targetPage === '' || targetPage === '/' || targetPage.indexOf('/auth') > -1 ) targetPage = '/packages';
      }
      console.log(targetPage)
      window.setTimeout(() => window.location.replace(targetPage), 1000);
    }
  }

  const handleEnterKey = e => {
    if ( e.key === 'Enter' ) {
      submitLogin();
    }
  }

    return (
      <Fragment>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Log In"}></Header>
            <br />
            <Container size={'narrow'}>
              <div className="">
                {staleSession &&
                    <>
                      <div className={'bg-red-300 p-10 rounded-lg'}>
                        <Translate text={"You might have been logged out for security reasons. Please log in again."} />
                      </div>
                      <br />
                    </>
                }
                <div>
                  <p className="mt-2 text-sm text-gray-600 text-center">
                    <Translate text={"Log in to your account here."} /> <Translate text={"If you don't have an account..."} />&nbsp;<a href={"/auth/register"}><Translate text={"register instead"} /></a>.<br /><Translate text={"Forgot password?"} />&nbsp;<a href={"/auth/recover"}><Translate text={"Reset it"} /></a>.
                  </p>
                </div>

                <br />
                <hr />
                <br />

                <div className="mt-8">
                  <div className="mt-6" onKeyPress={e => handleEnterKey(e)}>
                    <div className="space-y-6">
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"What's your phone # (or email)?"} />
                        </label>
                        <div className="mt-1">
                        <Input
                          id="userName"
                          name="userName"
                          className={"form-field-input"}
                          type="text"
                          value={credentials.userName}
                          autoComplete="userName"
                          onChange={e => handleChange(e)}
                        />
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 form-field-label">
                          <Translate text={"What's your password?"} />
                        </label>
                        <div className="mt-1">
                        <Input
                          id="password"
                          name="password"
                          className={"form-field-input"}
                          type="password"
                          value={credentials.password}
                          onChange={e => handleChange(e)}
                        />
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={() => submitLogin()}
                        >
                          <Translate text={"Log In"} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }