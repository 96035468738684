import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { ArrowUpTrayIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { Input } from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Translate from "../../../../../Utils/Translate";
import PackagesMenu from "../components/PackagesMenu";
import axios from "axios";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

export default function PreRegister() {

  useDocumentTitle('Pre-register a Package');

  const [packageInfo, setPackageInfo] = useState({});
  const [formDataBinary, setFormDataBinary] = useState({});
  const [page, setPage] = useState('preregister');
  const [fileName, setSelectedFileName] = useState('No file selected yet');
  const [hasFile, setHasFile] = useState(false);

  const handleChange = e => setPackageInfo({ ...packageInfo, [e.target.name]: e.target.value });

  useEffect(() => {
  }, []);

  const savePackageInfo = async () => {
    let body = packageInfo;
    const response = await Http.request({ uri: `/packages/pre-register`, body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success' && response.body.state) {
      localStorage.setItem('preRegisterState', response.body.state);
      setPage('invoice');
    }
  };

  const selectFile = async e => {
    if (e) e.preventDefault();
    let fileList = e.target.files;
    let files = [];
    let fileCount = 1;
    for (let key in fileList) {
      let file = fileList[key];
      if (file.name && file.size && fileCount === 1) {
        fileCount++;
        files.push(file);
      }
    }

    const formData = new FormData();
    formData.append('invoiceBinary', files[0]);
    formData.append('payload', JSON.stringify({ state: localStorage.preRegisterState }));
    setFormDataBinary(formData);

    setSelectedFileName(files[0].name);
    setHasFile(true);

    await axios.post(window.getServiceEndpoint() + `/packages/${localStorage.getItem('preRegisterState')}/add-invoice`, formData, window.getAxiosConfig())
      .then(result => {
        if (typeof result.data !== 'undefined') {
        }
        if (result.data.notification) {
          Notifier(result.data.notification);
        }
        window.location.assign('/packages/new');
      }).catch(e => {
        if (e.data.notification) {
          Notifier(e.data.notification);
        }
      });
  };

  const uploadInvoice = async () => {
    let response = await Http.request({ uri: '/packages/add-invoice' }, formDataBinary);
  }

  const clearFile = () => {
    setHasFile(false);
    setSelectedFileName('File removed.');
    setFormDataBinary({});
  }

  const getRandomPreRegistrationThankYou = () => {
    let thankYous = [
      "Wasn't that easy! ☺️",
      "Thanks for pre-registering!",
      "Pre-registration done. Great job!",
      "Pre-registration was successful. Thank you.",
      "Thanks. That will help us to track your package faster.",
      "Success! Thank you.",
      "Your package was pre-registered!",
      "Awesome! This package will show up in 'New'."
    ];
    return thankYous[4];
    const T = thankYous.length - 1;
    return thankYous[Math.ceil(Math.max(0, Math.min(T, Math.random() * T)))] || thankYous[T];
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      savePackageInfo();
    }
  }

  return (
    <>
      <div className="flex min-h-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Pre-register a Package"}></Header>
          <br />
          <Container>
            {(page === 'preregister' || page === '' || !page) &&
              <>
                <div className="" onKeyPress={e => handleEnterKey(e)}>

                  <div>
                    <p className="text-sm text-gray-600">
                      <h2 className={'text-lg'}>
                        <Translate text={"Did you ship a new package?"} />
                      </h2>
                      <br />
                      <Translate
                        text={"Register it here to improve tracking of movement and charges."} />&nbsp;<Translate
                        text={"The most important piece of information here is the tracking number from your shipper."} />
                    </p>
                    <br />
                    <p className="text-sm text-gray-600">
                      <strong><Translate text={"If your package hasn't shipped,"} /></strong>...<Translate text={"you can still add it here but please return and add the tracking number later when you have it."} />&nbsp;
                      <a href={"/packages"}><Translate text={"View packages here."} /></a>
                    </p>
                  </div>
                  <br />
                  <hr />
                  <br />

                  <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                    <div>
                      <label htmlFor="trackingNumber"
                        className="block text-sm font-medium text-gray-700 form-field-label">
                        <Translate text={"What's the tracking number?"} />
                      </label>
                      <div className="mt-1">
                        <Input
                          id="trackingNumber"
                          name="trackingNumber"
                          type="text"
                          className={"form-field-input"}
                          value={packageInfo.trackingNumber}
                          required={true}
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700 form-field-label">
                        <Translate text={"What's in the package?"} />
                      </label>
                      <div className="mt-1">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          className={"form-field-input"}
                          value={packageInfo.title}
                          required={true}
                          autoComplete="title"
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>

                  </div>

                  <br />

                  <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4">

                    <div>
                      <label htmlFor="value" className="block text-sm font-medium text-gray-700 form-field-label">
                        <Translate text={"What's the total value of contents?"} />
                      </label>
                      <div className="mt-1">
                        <Input
                          id="value"
                          name="value"
                          type="number"
                          className={"form-field-input"}
                          value={packageInfo.value}
                          required={true}
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>

                  </div>

                  <br />

                  <div>
                    <div>
                      <button
                        type="submit"
                        className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => savePackageInfo()}
                      >
                        <Translate text={"Continue"} />
                      </button>
                    </div>
                  </div>

                </div>
              </>
            }
            {page === 'invoice' &&
              <>
                <div className={'text-center'}>

                  <div className={''}>
                    <p className="mt-2 text-xl text-green-600">
                      <Translate
                        text={getRandomPreRegistrationThankYou()} />
                    </p>
                    <br />
                    <h1 className={"text-lg"}><strong><Translate text={"Do you have an invoice?"} /></strong></h1>

                    <p><Translate text={"If not, you may upload it later."} /></p>
                    <br />
                    <p><a href={"/packages/new"}>Back to Packages</a></p>
                  </div>
                  <br />
                  <hr />
                  <br />


                  <p className={'text-sm text-gray-700'}>
                    <Translate text={"If you have it now, upload below. It must be a PDF or image (JPG/JPEG/PNG) and 10 megs or less in size."} /></p>

                  <br />

                  <label htmlFor={"invoiceFileChooser"} className={'file-chooser-label'}><ArrowUpTrayIcon className={'inline'} height={"16"} /> <Translate text={"Select a file"} /></label>

                  <input type="file" id={"invoiceFileChooser"} hidden onChange={selectFile} multiple={false} />

                </div>
              </>
            }
          </Container>
        </div>
      </div>
    </>
  );
}
