import {InformationCircleIcon, PlusCircleIcon} from "@heroicons/react/20/solid";
import Translate from "../../../Utils/Translate";

export default props => {
    return (
        <>
            {[null, undefined, ''].includes(localStorage.getItem('access_token'))  &&
                <div className={'text-center text-lg'}>
                    <a href={"/auth/login"} className={'clickable rounded-md border border-transparent bg-green-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 force-text-white'}><Translate text={"Log In"} /></a> &nbsp; <a href={"/auth/register"} className={'clickable rounded-md border border-transparent bg-blue-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 force-text-white'}><Translate text={"Register"} /></a>
                    <br /><br />
                    <hr />
                    <br />
                </div>
            }
            <div className="submenu text-center">

                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2">

                    <div>
                        <a href={"/info/how-it-works"} className={props.page === 'how-it-works' ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"How it Works"} /></a>
                    </div>
                    <div>
                        <a href={"/info/how-to-pay"} className={props.page === 'payments' || props.page === '' || !props.page ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"How to Pay"} /></a>
                    </div>
                    <div>
                        <a href={"/info/rates"} className={props.page === 'rates' ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"Rates"} /></a>
                    </div>
                    <div>
                        <a href={"/info/delivery"} className={props.page === 'delivery' ? 'submenu-item submenu-active' : 'submenu-item'}><Translate text={"Delivery"} /></a>
                    </div>

                </div>

            </div>
            <br />
            <br />
        </>
    )
}