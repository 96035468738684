import { Fragment, useState } from "react";
import Http from "../../../../Utils/Http";
import Notifier from "../../../../Utils/Notifier";

import Template, { Input } from "../../../../Utils/Template";
import Header from "../../../../Components/Header";
import Container from "../../../../Components/Container";
import Translate from "../../../../Utils/Translate";
import useDocumentTitle from "../../../../Utils/DocumentTitle";

export default function ConfirmEmail() {

  useDocumentTitle('Confirm Account');

  const [confirmRequest, setConfirmRequest] = useState({ email: localStorage.getItem('REGISTER_PARAMS_EMAIL') });
  const [notificationChannel, setNotificationChannel] = useState(localStorage.getItem('stateChannel') || 'email');

  const handleChange = e => {
    setConfirmRequest({ ...confirmRequest, [e.target.name]: e.target.value });
  }

  const submitConfirmRequest = async () => {
    let body = { ...confirmRequest, state: localStorage.getItem('state') };
    const response = await Http.request({ uri: '/auth/register/confirm', body });
    if (response.notification) {
      Notifier(response.notification);
    }
    if (response.result === 'success') {
      localStorage.clear();
      window.location.replace(`/auth/login?PostSubscription`);
    }
  }

  const resendCode = async () => {
    let body = { state: localStorage.getItem('state') };
    const response = await Http.request({ uri: '/auth/register/send-code', body });
    if (response.body) {
      if (response.body.notification) {
        Notifier(response.body.notification);
      } else {
        if (response.result === 'success') {
          Notifier({ type: 'success', text: 'Done.' });
        }
      }
    } else {
      Notifier(response.notification);
    }
  }

  const handleEnterKey = e => {
    if (e.key === 'Enter') {
      submitConfirmRequest();
    }
  }

  return (
    <Fragment>
      <div className="flex min-h-full">
        <div className="w-full py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
          <Header title={"Complete Registration"}></Header>
          <br />
          <Container>
            <div className="">
              <div>
                <p className="mt-2 text-sm text-center">
                  {notificationChannel === 'email' &&
                    <><Translate text={"Thanks. We're in your email inbox (or, hopefully not...junk). Please check the email and retrieve the confirmation code."} /><br /><strong><Translate text={"If you don't find the email in your inbox, please check Spam, Updates, Notifications, etc."} /></strong></>
                  }
                  {notificationChannel === 'sms' &&
                    <>
                      <Translate text={"Thanks. We sent you a text just now. Please check your phone and retrieve the confirmation code."} />
                    </>
                  }
                  {!['email', 'sms'].includes(notificationChannel) &&
                    <>
                      <Translate text={"We sent you a confirmation code. Please retrieve the code and enter it below."} />
                    </>
                  }
                </p>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <div className="space-y-6">

                    <div onKeyPress={e => handleEnterKey(e)}>
                      <label htmlFor="confirmationCode" className="block text-sm font-medium text-gray-700">
                        <Translate text={"What's the confirmation code?"} />
                      </label>
                      <div className="mt-1">
                        <Input
                          id="confirmationCode"
                          name="confirmationCode"
                          value={confirmRequest.confirmationCode}
                          type="text"
                          onChange={e => handleChange(e)}
                        />
                      </div>
                    </div>

                    {notificationChannel === 'email' &&
                      <>
                        <br />
                        <big><Translate text={"Would you like to add a phone number?"} /></big>

                        <div>
                          <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                            <Translate text={"Add your phone number to improve our communication"} />:
                          </label>
                          <div className="mt-1">
                            <Input
                              id="phoneNumber"
                              name="phoneNumber"
                              type="phoneNumber"
                              value={confirmRequest.phoneNumber}
                              required={true}
                              onChange={e => handleChange(e)}
                            />
                          </div>
                        </div>
                      </>
                    }

                    {notificationChannel === 'sms' &&
                      <>
                        <br />
                        <big><Translate text={"Would you like to add an email address?"} /></big>

                        <div>
                          <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700">
                            <Translate text={"Add your email address"} />:
                          </label>
                          <div className="mt-1">
                            <Input
                              id="emailAddress"
                              name="emailAddress"
                              type="emailAddress"
                              value={confirmRequest.emailAddress}
                              required={true}
                              onChange={e => handleChange(e)}
                            />
                          </div>
                        </div>
                      </>
                    }

                    <div>
                      <button
                        type="submit"
                        className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => submitConfirmRequest()}
                      >
                        <Translate text={"Verify"} />
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="submit"
                        className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => resendCode()}
                      >
                        <Translate text={"Resend Code"} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Fragment>
  );

}