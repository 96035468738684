import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Packages from "./pages/Packages";
import Settings from "./pages/Settings";
import Login from "../Auth/Login";
import HowToPay from "../Info/pages/HowToPay";
import Info from "../Info";
import Payments from "./pages/Payments";
import Home from "../Info/pages/Home";
import {useEffect} from "react";

export default function Main() {

    useEffect(() => {
        if ( window.location.pathname === '/join' ) return window.location.assign('/auth/register');
        if ( window.location.pathname === '/the-journey' ) return window.location.assign('/info/how-it-works');
        localStorage.setItem('targetPage', window.location.pathname);
    }, []);

    return (
      <main>
          {(!localStorage.getItem('access_token') || localStorage.getItem('access_token') === 'invalid_client') &&
              <>
                  <Routes>
                      <Route path="*" element={<Login />}/>
                  </Routes>
              </>
          }

          {localStorage.getItem('access_token') && localStorage.getItem('access_token') !== 'invalid_client' &&
              <>
                  <Routes>
                      <Route path="packages/*" element={<Packages/>}/>
                      <Route path="settings/*" element={<Settings/>}/>
                      <Route path="info/*" element={<Info />}/>
                      <Route path="payments/*" element={<Payments />}/>
                      <Route path="*" element={<Home />}/>
                  </Routes>
              </>
          }
      </main>
    );
  }