import { ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Header from "../../../../../Components/Header";
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import Container from "../../../../../Components/Container";
import { Input } from "../../../../../Utils/Template";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import SettingsMenu from "../components/SettingsMenu";
import Translate from "../../../../../Utils/Translate";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ShippingAddress() {

    useDocumentTitle('Shipping Addresses');

    const [customerInfo, setCustomerInfo] = useState({});
    const [addressInfo, setAddressInfo] = useState({ addressParish: "Select Parish" });

    const handleChange = e => setAddressInfo({ ...addressInfo, [e.target.name]: e.target.value });

    const setAddressParish = addressParish => {
        setAddressInfo({ ...addressInfo, addressParish });
    }

    useEffect(() => {
        fetchDeliveryAddress();
    }, []);

    const fetchDeliveryAddress = async () => {
        let body = {};
        const response = await Http.request({ uri: `/account/get`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
        setAddressInfo({ ...addressInfo, ...response.body.deliveryAddress });
        setCustomerInfo(response.body);
    }

    const saveDeliveryAddress = async () => {
        let body = addressInfo;
        const response = await Http.request({ uri: `/account/patch/address`, body });
        if (response.notification) {
            Notifier(response.notification);
        }
    }

    const parishes = [
        { "id": "Kingston", "value": "Kingston" },
        { "id": "St. Andrew", "value": "St. Andrew" },
        { "id": "St. Catherine", "value": "St. Catherine" },
        { "id": "St. Mary", "value": "St. Mary" },
        { "id": "Portland", "value": "Portland" },
        { "id": "St. Thomas", "value": "St. Thomas" },
        { "id": "Clarendon", "value": "Clarendon" },
        { "id": "St. Ann", "value": "St. Ann" },
        { "id": "Manchester", "value": "Manchester" },
        { "id": "Trelawny", "value": "Trelawny" },
        { "id": "St. Elizabeth", "value": "St. Elizabeth" },
        { "id": "St. James", "value": "St. James" },
        { "id": "Hanover", "value": "Hanover" },
        { "id": "Westmoreland", "value": "Westmoreland" }
    ];

    const handleEnterKey = e => {
        if (e.key === 'Enter') {
            saveDeliveryAddress();
        }
    }

    return (
        <>
            <div className="flex min-h-full">
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
                    <Header title={"Addresses"}></Header>
                    <br />
                    <Container>
                        <div className="">

                            <SettingsMenu page={'addresses'} />

                            <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-10">
                                <div>
                                    <h2><strong><Translate text={"Your US Address"} /></strong></h2><br />
                                    <p>
                                        <Translate text={"When you shop online, send your packages to the following US address."} /></p>
                                    <br />
                                    <div className={'us-address-container p-2'}>
                                        <p>

                                            1905 NW 32nd Street, STE 7<br />
                                            <small className={'text-gray-500'}>
                                                <Translate text={"First line of address"} />
                                            </small>
                                        </p><br />
                                        <p>
                                            BOX-{customerInfo.boxNumber}<br />
                                            <small className={'text-gray-500'}>
                                                <Translate text={"Second line of address"} />
                                            </small>
                                        </p><br />
                                        <p>
                                            Pompano Beach<br />
                                            <small className={'text-gray-500'}>
                                                <Translate text={"City"} />
                                            </small>
                                        </p><br />
                                        <p>
                                            Florida<br />
                                            <small className={'text-gray-500'}>
                                                <Translate text={"State"} />
                                            </small>
                                        </p><br />
                                        <p>
                                            33064<br />
                                            <small className={'text-gray-500'}>
                                                <Translate text={"Zip Code"} />
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div onKeyPress={e => handleEnterKey(e)}>
                                    <h2><strong><Translate text={"Your Jamaican Address"} /></strong></h2>
                                    <br />
                                    <p>
                                        <Translate text={"When we clear your packages, what address should we deliver them to?"} /></p>
                                    <div>
                                        <br />
                                        <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700 form-field-label">
                                            <Translate text={"First line of address"} />:
                                        </label>
                                        <div className="mt-1">
                                            <Input
                                                id="addressLine1"
                                                name="addressLine1"
                                                type="text"
                                                className={"form-field-input"}
                                                value={addressInfo.addressLine1}
                                                required={true}
                                                autoComplete="addressLine1"
                                                onChange={e => handleChange(e)}
                                            />
                                        </div>
                                        <br />
                                        <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700 form-field-label">
                                            <Translate text={"Second line of address"} />:
                                        </label>
                                        <div className="mt-1">
                                            <Input
                                                id="addressLine2"
                                                name="addressLine2"
                                                type="text"
                                                className={"form-field-input"}
                                                value={addressInfo.addressLine2}
                                                required={false}
                                                autoComplete="addressLine2"
                                                onChange={e => handleChange(e)}
                                            />
                                        </div>
                                        <br />
                                        <label htmlFor="addressTown" className="block text-sm font-medium text-gray-700 form-field-label">
                                            <Translate text={"Town or district"} />:
                                        </label>
                                        <div className="mt-1">
                                            <Input
                                                id="addressTown"
                                                name="addressTown"
                                                type="text"
                                                className={"form-field-input"}
                                                value={addressInfo.addressTown}
                                                required={true}
                                                autoComplete="addressTown"
                                                onChange={e => handleChange(e)}
                                            />
                                        </div><br />
                                        <label htmlFor="addressLine1" className="block text-sm font-medium text-gray-700 form-field-label">
                                            <Translate text={"Parish"} />:
                                        </label>
                                        <div className="mt-1">
                                            <Listbox value={addressInfo.addressParish} onChange={setAddressParish}>
                                                {({ open }) => (
                                                    <>
                                                        <div className="relative mt-1">
                                                            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm">
                                                                <span className="block truncate">{addressInfo.addressParish}</span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                </span>
                                                            </Listbox.Button>

                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {parishes.map((parish) => (
                                                                        <Listbox.Option
                                                                            key={parish.value}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={parish.value}
                                                                        >
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                        {parish.value}
                                                                                    </span>

                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'text-white' : 'text-blue-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox>
                                        </div>
                                        <br />
                                        <div>
                                            <button
                                                type="submit"
                                                className="flex rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                onClick={() => saveDeliveryAddress()}
                                            >
                                                <Translate text={"Save"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}