import React, {Fragment, useEffect, useState} from "react";
import Header from "../../../../../Components/Header";
import Http from "../../../../../Utils/Http";
import Notifier from "../../../../../Utils/Notifier";
import {CurrencyText, DateText, RelativeDate} from "../../../../../Utils/Template";
import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  InformationCircleIcon, PlusCircleIcon,
  ScaleIcon,
  StarIcon
} from "@heroicons/react/20/solid";
import moment from "moment";
import Container from "../../../../../Components/Container";
import {ArrowRightCircleIcon, EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import Translate from "../../../../../Utils/Translate";
import SettingsMenu from "../components/PaymentsMenu";
import PackagesMenu from "../components/PaymentsMenu";
import axios from "axios";
import PaymentsMenu from "../components/PaymentsMenu";
import useDocumentTitle from "../../../../../Utils/DocumentTitle";

export default function Statement(props) {

  useDocumentTitle('Account Statement');

  const [statement, setStatement] = useState({stats: []});

  useEffect(() => {
    fetchStatement();
  }, []);

  const fetchStatement = async () => {
    let body = {};
    const response = await Http.request({uri: `/payments/statement`, body});
    if (response.notification) {
      Notifier(response.notification);
    }
    setStatement(response.body.statement);
  }

  return (
      <>
        <div className="flex min-h-full">
          <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-col lg:px-20 xl:px-24">
            <Header title={"Account Statement"}></Header>
            <br />
            <div className={'text-center'}>
            <span className={'clickable rounded-md border border-transparent bg-lynk-inky-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-lynk-inky-black focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/pay-with-lynk')}><Translate text={"Pay with Lynk"} /></span>&nbsp;&middot;&nbsp;<span className={'clickable rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/card-payment')}><CreditCardIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Pay with Card"} /></span>&nbsp;&middot;&nbsp;<span className={'clickable rounded-md border border-transparent bg-orange-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 force-text-white'} onClick={() => window.location.assign('/payments/transactions/post-payment')}><PlusCircleIcon height={'18'} className={'inline'} />&nbsp;<Translate text={"Post a Payment"} /></span>
            </div>
            <br />
            <Container>
              <div className="">

                <PaymentsMenu page={props.page || 'statement'} />

                <div className="">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    <Translate text={"Account Summary"} />
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {statement.stats.map((item) => (
                        <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.value}</dd>
                        </div>
                    ))}
                  </dl>
                  <br />
                  <hr />
                  {statement.payableBalance > 0 &&
                  <>
                    <br />
                    <div className={'p-5 bg-red-200 text-center'}><Translate text={"Please pay"} />:{' '}<strong>JM<CurrencyText value={statement.payableBalanceJmd} /></strong>{' '}<Translate text={'or'} />{' '}<strong>US<CurrencyText value={statement.payableBalance} /></strong><a href={"/payments/transactions/card-payment"}>&nbsp;<ArrowRightCircleIcon height={"22"} className={"inline"} />&nbsp;</a></div>
                    <br />
                    <hr />
                  </>
                  }
                  <br />
                  <p>
                    <strong><Translate text={"Balance"} /></strong><br />
                    <Translate text={"This is your account balance in dollars, reflecting the total charges on unpaid packages. If your balance is negative, it means you made an overpayment or we refunded some money."} /><br />
                    <a href={"/payments/transactions/card-payment"}><strong><Translate text={"Pay with Card"} /></strong></a> &middot;&nbsp;<a href={"/payments/transactions/post-payment"}><Translate text={"Post a Payment"} /></a>
                  </p>
                  <br />
                  <p>
                    <strong><Translate text={"Points"} /></strong><br />
                    <Translate text={"It's the total number of reward points that have not been redeemed."} /><br />
                    <a href={"/payments/rewards"}><Translate text={"See Rewards"} /></a>
                  </p>
                  <br />
                  <p>
                    <strong><Translate text={"Net Balance"} /></strong><br />
                    <Translate text={"If you should redeem your points, this would be the new balance to be paid in dollars. It includes credits."} />
                  </p>
                  <br />
                  <p className={"text-gray-600 text-sm"}>
                    <Translate text={"Still need help? Reach us via live chat."} />
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </div>

      </>
  );
}